import { DeliveryWindow, Order, Product } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useExpand } from '../../../../utils/hooks';
import { isDefined } from '../../../../utils/is';
import { DelwinNameAndDate } from '../../../various/DelwinNameAndDate';
import Icon, { IconColor, IconType } from '../../../various/Icon';
import { ProductDetailsShared } from '../../ProductDetailsShared';
import { ReceiptRow } from '../ReceiptRow';

import styles from './ExpandableOrder.module.scss';

interface Props {
  order: Order.Open;
  index: number;
  products: never[] | Record<Product.Id, Product.Full>;
  deliveryWindows: DeliveryWindow.Mixed[];
}

export const ExpandableOrder = ({ order, products, index, deliveryWindows }: Props) => {
  const { t } = useTranslation(['common', 'totals', 'orders']);

  const id = React.useId();

  const orderDeliveryWindows = order.deliveryWindows
    .map(delwinId => deliveryWindows.find(delwin => delwin.deliveryWindow === delwinId))
    .filter(isDefined);

  const { isOpen, toggle, wrapperRef } = useExpand<HTMLDivElement>({
    initialOpen: false,
  });

  return (
    <>
      <ReceiptRow className={styles.row}>
        <div>
          {t('orders:order_index', { index: index + 1 })}
          <div className={styles.deliveryWindows}>
            {orderDeliveryWindows.map(deliveryWindow => (
              <DelwinNameAndDate key={deliveryWindow.deliveryWindow} deliveryWindow={deliveryWindow} />
            ))}
          </div>
        </div>
        <div>{order.totals.itemsCount}</div>
        <div>{order.totals.itemsTotalPrice}</div>
        <button
          type="button"
          className={styles.expandButton}
          aria-label={t('common:expand_row')}
          aria-expanded={isOpen}
          aria-controls={id}
          onClick={toggle}
        >
          <Icon type={IconType.ArrowBlackDown} color={IconColor.Medium} rotation={isOpen ? 180 : 0} />
        </button>
      </ReceiptRow>
      <div id={id} ref={wrapperRef} className={cx(styles.expander, { [styles.visible]: isOpen })}>
        <ProductDetailsShared
          delwinIds={order.deliveryWindows}
          order={order}
          products={products}
          orderVariants={order.products.filter(product => order.deliveryWindows.includes(product.deliveryWindow))}
        />
      </div>
    </>
  );
};
