import { DeliveryWindow, Order } from '@typings';
import { useSelector } from 'react-redux';

import universalStyles from '../../../../css/utilities/universal.module.scss';
import { getCheckoutFields, getOrderDetails } from '../../../ducks';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { CheckoutSection } from '../CheckoutSection';

import { CheckoutSplitOrderPreferences } from './CheckoutSplitOrderPreferences';

interface Props {
  deliveryWindows: DeliveryWindow.Mixed[];
  splitOrders: Order.OpenSplit[];
}

export const CheckoutSplitOrdersPreferences = ({ deliveryWindows, splitOrders }: Props) => {
  const orderDetails = useSelector(getOrderDetails);
  const checkoutFields = useSelector(getCheckoutFields);

  const hasMultipleOrders = splitOrders.length > 1;
  const isSplitByDeliveryWindow = splitOrders[0]?.splitBy === 'delwin';

  const hasAnyPreferredShippingDate =
    isDefined(checkoutFields.preferredShippingDate) || isDefined(checkoutFields.preferredShippingDateByDeliveryWindow);
  const hasAnyCancelShippingDate = isDefined(checkoutFields.cancelDate) || isDefined(checkoutFields.cancelDateByDeliveryWindow);

  const shouldShowShippingPreferences = (hasAnyPreferredShippingDate || hasAnyCancelShippingDate) && isSplitByDeliveryWindow;
  const shouldShowShippingMethods = !isEmpty(splitOrders[0]?.shippingMethods ?? []);

  if (!hasMultipleOrders) {
    return null;
  }

  if (!shouldShowShippingPreferences && !shouldShowShippingMethods) {
    return null;
  }

  if (shouldShowShippingPreferences && shouldShowShippingMethods) {
    return (
      <>
        {splitOrders.map((order, index) => (
          <CheckoutSection key={order.order}>
            <CheckoutSplitOrderPreferences
              key={order.order}
              orderId={orderDetails.order.order}
              splitOrder={order}
              deliveryWindows={deliveryWindows}
              index={index}
            />
          </CheckoutSection>
        ))}
      </>
    );
  }

  return (
    <CheckoutSection>
      <div className={universalStyles.twoColumnsGrid}>
        {splitOrders.map((order, index) => (
          <CheckoutSplitOrderPreferences
            key={order.order}
            orderId={orderDetails.order.order}
            splitOrder={order}
            deliveryWindows={deliveryWindows}
            index={index}
          />
        ))}
      </div>
    </CheckoutSection>
  );
};
