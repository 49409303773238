import { DeliveryWindow, Order, Product } from '@typings';
import { useTranslation } from 'react-i18next';

import { ExpandableOrder } from '../ExpandableOrder';
import { ReceiptRow } from '../ReceiptRow';

import styles from './SplitOrdersReceipt.module.scss';

interface Props {
  orders: Order.Open[];
  products: never[] | Record<Product.Id, Product.Full>;
  deliveryWindows: DeliveryWindow.Mixed[];
}

export const SplitOrdersReceipt = ({ orders, products, deliveryWindows }: Props) => {
  const { t } = useTranslation(['common', 'totals', 'orders']);

  return (
    <div>
      <ReceiptRow className={styles.headers}>
        <div>{t('orders:order_other')}</div>
        <div>{t('common:unit_other')}</div>
        <div>{t('totals:subtotal')}</div>
      </ReceiptRow>
      <div className={styles.container}>
        {orders.map((order, index) => (
          <ExpandableOrder key={order.order} index={index} order={order} products={products} deliveryWindows={deliveryWindows} />
        ))}
      </div>
    </div>
  );
};
