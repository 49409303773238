/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 16;

export const Party = ({ width = ORIGINAL_WIDTH, height = width, color = 'currentColor' }: IconComponentProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16" fill={color}>
      <path d="M7.469 3.29a.987.987 0 0 0-1.625.36l-3.281 9.024A.99.99 0 0 0 3.486 14a1 1 0 0 0 .34-.063l9.024-3.28a.986.986 0 0 0 .361-1.626L7.47 3.29Zm-.521 8.45L4.761 9.552l.822-2.263 3.628 3.629-2.263.822Zm-3.437 1.25.875-2.4 1.528 1.527-2.403.873Zm6.74-2.45L5.96 6.25l.812-2.242 5.714 5.715-2.236.817Zm.25-6.04c.009-.338.09-.67.24-.974.33-.662.956-1.026 1.76-1.026.418 0 .687-.143.853-.45.087-.173.137-.361.147-.554a.5.5 0 0 1 1 .004c0 .804-.533 2-2 2-.42 0-.688.143-.854.45-.087.173-.137.361-.146.554a.5.5 0 1 1-1-.004ZM9 2.5V1a.5.5 0 1 1 1 0v1.5a.5.5 0 1 1-1 0Zm6.353 5.146a.5.5 0 0 1-.707.707l-1-1a.5.5 0 0 1 .707-.707l1 1Zm.305-2.672-1.5.5a.5.5 0 0 1-.317-.948l1.5-.5a.5.5 0 0 1 .317.948Z" />
    </svg>
  );
};
