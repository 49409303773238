import cx from 'classnames';

import styles from './ReceiptRow.module.scss';

interface Props {
  className?: string;
}

export const ReceiptRow = ({ children, className }: React.WithChildren<Props>) => {
  return <div className={cx(styles.row, className)}>{children}</div>;
};
