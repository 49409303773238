import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import universalStyles from '../../../../css/utilities/universal.module.scss';
import { getUserNavigation } from '../../../ducks';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { NavigationItem } from '../../navigation/NavigationItem';
import styles from '../Menu.module.scss';

import { ExpandableItem } from './ExpandableItem';

export const SideNavigation = () => {
  const { t } = useTranslation(['common']);
  const navigationItems = useSelector(getUserNavigation);

  const itemProps = {
    activeClassName: styles.active,
    linkClassName: styles.menuItem,
  };

  return (
    <nav>
      <h2 className={universalStyles.srOnly}>{t('common:main_menu')}</h2>
      <ul>
        {navigationItems.map(item => {
          if (!isDefined(item.children) || isEmpty(item.children)) {
            return (
              <li key={item.label}>
                <NavigationItem item={item} {...itemProps} />
              </li>
            );
          }

          return <ExpandableItem key={item.label} item={item} {...itemProps} />;
        })}
      </ul>
    </nav>
  );
};
