import { Checkboxes, DeliveryWindow, Id, Product } from '@typings';
import { groupBy, prop } from 'ramda';

import { formatDate, getDateOrNull, getHumanReadableTimePeriod } from '../utils/dates';
import { isDefined } from '../utils/is';

export function isRunning(deliveryWindow: DeliveryWindow.Mixed): deliveryWindow is DeliveryWindow.Running {
  return 'atOnce' in deliveryWindow && deliveryWindow.atOnce;
}

export function isSeasonal(deliveryWindow: DeliveryWindow.Mixed): deliveryWindow is DeliveryWindow.Seasonal {
  return 'atOnce' in deliveryWindow && !deliveryWindow.atOnce;
}

export const isDeliveryWindowOrderGroup = (
  candidate: DeliveryWindow.DeliveryWindowId | DeliveryWindow.DeliveryWindowsGroup,
): candidate is DeliveryWindow.DeliveryWindowsGroup => 'groupId' in candidate;
export const isDeliveryWindowFilterGroup = (
  candidate: DeliveryWindow.DeliveryWindowFilterItem | DeliveryWindow.DeliveryWindowId | DeliveryWindow.DeliveryWindowsGroup,
): candidate is DeliveryWindow.DeliveryWindowFilterGroup => 'groupId' in candidate;

export function getHumanTimePeriod(deliveryWindow: DeliveryWindow.Mixed) {
  if (isRunning(deliveryWindow)) {
    return {
      deliveryPeriod: 'Ongoing',
      saleEndDate: null,
    };
  }

  const { endDate, saleEndDate, startDate } = deliveryWindow;
  const format = 'MMM D';

  const humanDeliveryPeriod =
    isDefined(startDate) && isDefined(endDate) ? getHumanReadableTimePeriod(' - ')(format)([startDate, endDate]) : null;
  const humanSaleEndDate = isDefined(saleEndDate) ? formatDate(format)(saleEndDate) : null;

  return { deliveryPeriod: humanDeliveryPeriod, saleEndDate: humanSaleEndDate };
}

export const getIsExpired = (deliveryWindow: DeliveryWindow.Mixed, activeDeliveryWindows: Id[]) =>
  !activeDeliveryWindows.includes(deliveryWindow.deliveryWindow);

export const getSortedDeliveryWindowsIds = (delwins: string[], sortingArray: string[]) => {
  return [...delwins].sort((delwinA, delwinB) => sortingArray.indexOf(delwinA.toString()) - sortingArray.indexOf(delwinB.toString()));
};

export const getDeliveryWindowIdsFromVariants = (variants: (Product.Full | Product.Standard)[]) => {
  const deliveryWindows = variants.flatMap(variant => variant.deliveryWindows);

  return deliveryWindows.map(id => id.toString());
};

export const getDeliveryWindowsIdsByStockType = (deliveryWindows: DeliveryWindow.Mixed[]) => {
  const stockTypeGroups = groupBy(prop('stockType'), deliveryWindows);

  return Object.entries(stockTypeGroups).reduce<Record<DeliveryWindow.StockType, DeliveryWindow.Id[]>>(
    (acc, [stockType, delwins]) => {
      return {
        ...acc,
        [stockType]: delwins.map(deliveryWindow => deliveryWindow.deliveryWindow),
      };
    },
    { preorder: [], stock: [] },
  );
};

export const getDeliveryWindowsCheckboxOptions = (options: DeliveryWindow.DeliveryWindowFilterItem[]) => {
  return options.reduce((acc: Checkboxes.Option[], deliveryWindowFilterItem) => {
    const isGroup = isDeliveryWindowFilterGroup(deliveryWindowFilterItem);

    if (isGroup) {
      const childItems = deliveryWindowFilterItem.items.map(deliveryWindow => ({
        name: deliveryWindow.name,
        parentValue: deliveryWindowFilterItem.groupId,
        value: deliveryWindow.deliveryWindow,
      }));

      return [
        ...acc,
        {
          name: deliveryWindowFilterItem.name,
          value: deliveryWindowFilterItem.groupId,
        },
        ...childItems,
      ];
    }

    return [
      ...acc,
      {
        name: deliveryWindowFilterItem.name,
        value: deliveryWindowFilterItem.deliveryWindow,
      },
    ];
  }, []);
};

export const getDeliveryWindowsByIds = (deliveryWindows: DeliveryWindow.Mixed[], deliveryWindowsIds: Id[]) =>
  deliveryWindows.filter(deliveryWindow => deliveryWindowsIds.includes(deliveryWindow.deliveryWindow));

export const getShippingDateBy = (shippingDateBy: 'startDate' | 'endDate', deliveryWindow: DeliveryWindow.Mixed | undefined) => {
  if (!isDefined(deliveryWindow) || isRunning(deliveryWindow)) {
    return null;
  }

  return getDateOrNull(deliveryWindow[shippingDateBy]);
};
