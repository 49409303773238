import { CheckoutForm, DeliveryWindow, Id, Order } from '@typings';
import cx from 'classnames';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import universalStyles from '../../../../css/utilities/universal.module.scss';
import { getCheckoutFields } from '../../../ducks';
import { getShippingDateBy } from '../../../logic/deliveryWindows';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { CheckoutShippingMethods } from '../../orders/partials/CheckoutShippingMethods';
import { DelwinNameAndDate } from '../../various/DelwinNameAndDate';
import { DatePickerField } from '../../various/Form';
import Heading from '../../various/Heading';

import styles from './CheckoutSplitOrdersPreferences.module.scss';

interface Props {
  orderId: Id;
  splitOrder: Order.OpenSplit;
  deliveryWindows: DeliveryWindow.Mixed[];
  index: number;
}

export const CheckoutSplitOrderPreferences = ({ orderId, splitOrder, deliveryWindows, index }: Props) => {
  const { t } = useTranslation(['checkout', 'orders', 'shipping']);
  const checkoutFields = useSelector(getCheckoutFields);

  const shippingDatesPreferences = useWatch<CheckoutForm, 'shippingDatesPreferences'>({ name: 'shippingDatesPreferences' });

  const orderDeliveryWindows = splitOrder.deliveryWindows
    .map(delwinId => deliveryWindows.find(delwin => delwin.deliveryWindow === delwinId))
    .filter(isDefined);

  const [firstDeliveryWindow] = orderDeliveryWindows;
  const isSplitByDeliveryWindow = splitOrder.splitBy === 'delwin';

  const hasAnyPreferredShippingDate =
    isDefined(checkoutFields.preferredShippingDate) || isDefined(checkoutFields.preferredShippingDateByDeliveryWindow);
  const hasAnyCancelShippingDate = isDefined(checkoutFields.cancelDate) || isDefined(checkoutFields.cancelDateByDeliveryWindow);

  const shouldShowShippingPreferences = (hasAnyPreferredShippingDate || hasAnyCancelShippingDate) && isSplitByDeliveryWindow;
  const shouldShowShippingMethods = !isEmpty(splitOrder.shippingMethods ?? []);

  if (!shouldShowShippingPreferences && !shouldShowShippingMethods) {
    return null;
  }

  const getShippingStartDate = () => {
    return getShippingDateBy('startDate', firstDeliveryWindow);
  };

  const getCancelDateMin = () => {
    const delwinId = firstDeliveryWindow?.deliveryWindow;

    if (!isDefined(delwinId)) {
      return null;
    }

    const shippingDate = shippingDatesPreferences?.[delwinId]?.preferredShippingDate;

    return shippingDate?.add(1, 'day') ?? getShippingStartDate();
  };

  const getShippingDateMax = () => {
    const delwinId = firstDeliveryWindow?.deliveryWindow;

    if (!isDefined(delwinId)) {
      return null;
    }

    const cancelDate = shippingDatesPreferences?.[delwinId]?.cancelDate;

    return cancelDate?.subtract(1, 'day') ?? getShippingDateBy('endDate', firstDeliveryWindow);
  };

  return (
    <div className={styles.wrapper}>
      <Heading title={t('orders:order_index', { index: index + 1 })} className={styles.header} />
      <div className={styles.deliveryWindows}>
        {orderDeliveryWindows.map(deliveryWindow => (
          <DelwinNameAndDate key={deliveryWindow.deliveryWindow} deliveryWindow={deliveryWindow} iconClassName={styles.delwinIcon} />
        ))}
      </div>
      <div
        className={cx(styles.container, { [universalStyles.twoColumnsGrid]: shouldShowShippingPreferences && shouldShowShippingMethods })}
      >
        {shouldShowShippingPreferences && !isEmpty(orderDeliveryWindows) && (
          <div>
            <h3 className={styles.subheader}>{t('shipping:shipping_preferences')}</h3>
            <div className={styles.fields}>
              {hasAnyPreferredShippingDate && (
                <DatePickerField<CheckoutForm>
                  name={`shippingDatesPreferences.${orderDeliveryWindows[0]?.deliveryWindow}.preferredShippingDate`}
                  label={t('shipping:pref_shipping_date')}
                  isRequired={!!checkoutFields.preferredShippingDateByDeliveryWindow?.required}
                  size="regular"
                  dataTestId="shipping-date-picker"
                  inputDataTestId={`shipping-date-picker-input-${index}`}
                  min={getShippingStartDate()}
                  max={getShippingDateMax()}
                />
              )}

              {hasAnyCancelShippingDate && (
                <DatePickerField<CheckoutForm>
                  name={`shippingDatesPreferences.${orderDeliveryWindows[0]?.deliveryWindow}.cancelDate`}
                  label={t('checkout:cancel_date')}
                  isRequired={!!checkoutFields.cancelDateByDeliveryWindow?.required}
                  size="regular"
                  dataTestId="cancel-date-picker"
                  inputDataTestId={`cancel-date-picker-input-${index}`}
                  min={getCancelDateMin()}
                />
              )}
            </div>
          </div>
        )}
        {shouldShowShippingMethods && (
          <div>
            <h3 className={styles.subheader}>{t('shipping:shipping_method')}</h3>
            <CheckoutShippingMethods
              orderId={orderId}
              splitId={splitOrder.order}
              methods={splitOrder.shippingMethods ?? []}
              selectedMethods={splitOrder.selectedShippingMethods ?? []}
            />
          </div>
        )}
      </div>
    </div>
  );
};
