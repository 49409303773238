import {
  Addresses,
  Checkout,
  CheckoutForm,
  Currency,
  DeliveryWindow,
  FailedProducts,
  Filters,
  Id,
  Invoice,
  Order,
  Prepack,
  Product,
  Totals,
  Unit,
} from '@typings';
import { createAction } from 'typesafe-actions';

import { authenticatedPaths } from '../../paths';

export const fetchOrderRequest = createAction('order/ORDER_FETCH_REQUEST')<string>();
export const fetchOrderSuccess = createAction('order/ORDER_FETCH_SUCCESS')<Order.Single | Order.Split>();
export const fetchOrderFailure = createAction('order/ORDER_FETCH_FAILURE')();

export const fetchInvoicesRequest = createAction('order/INVOICES_FETCH_REQUEST')<Order.Id>();
export const fetchInvoicesSuccess = createAction('order/INVOICES_FETCH_SUCCESS')<Invoice.Standard[]>();
export const fetchInvoicesFailure = createAction('order/INVOICES_FETCH_FAILURE')();

export const checkoutOrderRequest = createAction('order/ORDER_CHECKOUT_REQUEST')<Checkout.Data & { order: Order.Id }>();
export const updateCheckoutForm = createAction('order/CHECKOUT_FORM_UPDATE')<CheckoutForm>();
export const resetCheckoutForm = createAction('order/CHECKOUT_FORM_RESET')();

export const checkoutOrderSuccess = createAction('order/ORDER_CHECKOUT_SINGLE_SUCCESS')<Checkout.Single>();
export const checkoutOrderFailure = createAction('order/ORDER_CHECKOUT_FAILURE')<Checkout.Failure>();
export const checkoutOrderUnexpectedFailure = createAction('order/ORDER_CHECKOUT_UNEXPECTED_FAILURE')();

export const checkoutOrderSplitSuccess = createAction('order/ORDER_CHECKOUT_SPLIT_SUCCESS')<{
  currency: Currency;
  orders: Tuple<Order.Essentials> | Tuple<Order.Closed>;
  originalOrderId: string;
}>();

export const updateTotalsByShippingAddress = createAction('order/UPDATE_ORDER_BY_SHIPPING_ADDRESS')<Addresses.ShippingAddressData>();

export const editSelection = createAction('order/SELECTION_EDIT_REQUEST')<{
  orderId: Id;
  name: string;
}>();

export const editSelectionSuccess = createAction('order/SELECTION_EDIT_SUCCESS')<Order.Single>();
export const editSelectionFailure = createAction('order/SELECTION_EDIT_FAILURE')();
export const selectWorkingOrder = createAction('order/WORKING_ORDER_SELECT')<Order.Id>();

export const copyToNewSelectionRequest = createAction('order/COPY_TO_NEW_SELECTION_REQUEST')<{
  name: string;
  orderId: Id;
  orderNumber: string;
  buyer?: string;
}>();

export const copyToNewSelectionSuccess = createAction('order/COPY_TO_NEW_SELECTION_SUCCESS')<{
  failedProducts: Nullable<{
    products: Record<Product.Id, Product.Full>;
    failedProducts: FailedProducts.ItemsMissing[];
  }>;
}>();

export const copyToNewSelectionFailure = createAction('order/COPY_TO_NEW_SELECTION_FAILURE')();

export const createSelection = createAction('order/SELECTION_CREATE_REQUEST')<{
  name: string;
  redirectPath?: keyof Pick<typeof authenticatedPaths, 'HOME' | 'PRODUCTS_ORDER'>;
  buyer?: string;
  initialStockType?: DeliveryWindow.StockType;
}>();

export const createSelectionSuccess = createAction('order/SELECTION_CREATE_SUCCESS')<Order.Single>();
export const createSelectionFailure = createAction('order/SELECTION_CREATE_FAILURE')();

export const addProductsToOrder = createAction('order/PRODUCTS_ADD_TO_ORDER_REQUEST')<{
  isAddingByBarcode?: boolean;
  products: Product.Id[];
  deliveryWindows: DeliveryWindow.Id[];
}>();

export const addProductsToOrderSuccess = createAction('order/PRODUCTS_ADD_TO_ORDER_SUCCESS')<Responses.ItemsAdded>();

export const addProductsToOrderFailure = createAction('order/PRODUCTS_ADD_TO_ORDER_FAILURE')<{
  products: Product.Id[];
}>();

export const addAllProductsToOrderRequest = createAction('order/PRODUCTS_ADD_ALL_TO_ORDER_REQUEST')<{
  filters: Partial<Filters>;
  products: Product.Id[];
}>();

export const addAllProductsToOrderSuccess = createAction('PRODUCTS_ADD_ALL_TO_ORDER_SUCCESS')<Responses.ItemsAdded>();

export const addAllProductsToOrderFailure = createAction('order/PRODUCTS_ADD_ALL_TO_ORDER_FAILURE')();

export const removeProductsFromOrder = createAction('order/PRODUCTS_REMOVE_FROM_ORDER_REQUEST')<Product.RemovePayload>();

export const removeProductsFromOrderSuccess = createAction('order/PRODUCTS_REMOVE_FROM_ORDER_SUCCESS')<Responses.ItemsRemoved>();

export const removeProductsFromOrderFailure = createAction('order/PRODUCTS_REMOVE_FROM_ORDER_FAILURE')<{
  products: Product.Id[];
}>();

export const setVoucherCode = createAction('order/VOUCHER_CODE_SET')<string>();
export const addVoucherRequest = createAction('order/VOUCHER_ADD_REQUEST')<string>();
export const addVoucherSuccess = createAction('order/VOUCHER_ADD_SUCCESS')<Order.Single>();
export const addVoucherFailure = createAction('order/VOUCHER_ADD_FAILURE')<Responses.Errors>();
export const removeVoucherRequest = createAction('order/VOUCHER_REMOVE_REQUEST')<string>();
export const removeVoucherSuccess = createAction('order/VOUCHER_REMOVE_SUCCESS')<Order.Single>();
export const removeVoucherFailure = createAction('order/VOUCHER_REMOVE_FAILURE')();

export const showTerms = createAction('order/TERMS_SHOW')();
export const loadTermsSuccess = createAction('order/TERMS_LOAD_SUCCESS')<Responses.TermsAndConditions>();
export const loadTermsFailure = createAction('order/TERMS_LOAD_FAILURE')();

export const loadInitialOrderDetails = createAction('order/INITIAL_ORDER_DETAILS_LOAD')();
export const resetCheckoutSuccess = createAction('order/CHECKOUT_RESET_SUCCESS')();

export const setItemsRequest = createAction('order/ITEMS_SET_REQUEST')<Omit<Unit.Stateful, 'variant'>>();
export const setMultipleItemsRequest = createAction('order/MULTIPLE_ITEMS_SET_REQUEST')<{
  items: Omit<Unit.Stateful, 'variant' | 'product'>[];
  product: string;
}>();

export const setItemsSuccess = createAction('order/ITEMS_SET_SUCCESS')<Responses.ItemsUpdated>();
export const setItemsFailure = createAction('order/ITEMS_SET_FAILURE')();

export const resetOrderData = createAction('order/RESET_ORDER_DATA')();

export const addShippingAddress = createAction('order/ADD_SHIPPING_ADDRESS')<Addresses.MixedShipping>();

export const setPrepacksRequest = createAction('order/SET_PREPACKS_REQUEST')<{
  data: Prepack.Data;
  items: Unit.ItemsDTO;
  product: string;
}>();

export const removePrepacksRequest = createAction('order/REMOVE_PREPACKS_REQUEST')<{
  data: Omit<Prepack.Data, 'prepacks'>;
  product: string;
}>();

export const updateDefaultShippingAddress = createAction('order/UPDATE_DEFAULT_SHIPPING_ADDRESS')<Addresses.Shipping>();

export const updateOrderByShippingAddressSuccess = createAction('order/UPDATE_ORDER_IN_ORDER_SUCCESS')<{
  order: { totals: Totals.Full };
  splitOrders?: Order.OpenSplit[];
}>();

export const updateTotalsByShippingAddressFailure = createAction('order/UPDATE_ORDER_IN_ORDER_FAILURE')();

export const clearFailedProducts = createAction('order/CLEAR_FAILED_PRODUCTS')();

export const setActiveOrderPaymentMethod = createAction('order/SET_PAYMENT_METHOD')<Nullable<string>>();

export const setIsRequestingSplitOrder = createAction('order/SET_IS_REQUESTING_SPLIT_ORDER')<boolean>();

export const updateShippingRequest = createAction('order/UPDATE_SHIPPING_REQUEST')<{ orderId: string }>();
export const updateShippingSuccess = createAction('order/UPDATE_SHIPPING_SUCCESS')<Order.Single & { orderId: string }>();
export const updateShippingFailure = createAction('order/UPDATE_SHIPPING_FAILURE')<{ orderId: string }>();
