import i18next from 'i18next';
import { push } from 'redux-first-history';
import { combineEpics } from 'redux-observable';
import { EMPTY, from } from 'rxjs';
import { debounceTime, filter, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { addToast } from '../components/various/Toasts';
import {
  authoriseAccessSuccess,
  getActiveDeliveryWindowsIdsForOrderBuyer,
  getBuyerIdFromCurrentOrder,
  getIsLoggedIn,
  getIsSeller,
} from '../ducks';
import {
  addAllProductsToOrderFailure,
  addAllProductsToOrderRequest,
  addAllProductsToOrderSuccess,
  checkoutOrderFailure,
  checkoutOrderRequest,
  checkoutOrderSplitSuccess,
  checkoutOrderSuccess,
  fetchInvoicesFailure,
  fetchInvoicesRequest,
  fetchInvoicesSuccess,
  getOrderDetails,
  getOrderId,
  removePrepacksRequest,
  setItemsFailure,
  setItemsSuccess,
  setPrepacksRequest,
  updateOrderByShippingAddressSuccess,
  updateTotalsByShippingAddress,
  updateTotalsByShippingAddressFailure,
} from '../ducks/order';
import { fetchOrdersRequest } from '../ducks/selections';
import { compiledPaths, paths } from '../paths';
import { mapResponse } from '../utils/operators/mapResponse';

import { handleCheckoutFailure, handleCheckoutSuccess } from './responseHandlers';

const DEBOUNCE_TIME = 150;

const checkoutOrderEpic: AppEpic = (action$, state$, { ordersRepository }) => {
  return action$.pipe(
    filter(isActionOf(checkoutOrderRequest)),
    mergeMap(action => {
      const orderId = getOrderId(state$.value);
      const data = action.payload;

      return from(ordersRepository.checkoutOrder(data, orderId)).pipe(
        mapResponse(
          res => handleCheckoutSuccess(res.data, action.payload.order),
          error => handleCheckoutFailure(error),
        ),
      );
    }),
  );
};

const successCheckoutEpic: AppEpic = action$ => {
  return action$.pipe(
    filter(isActionOf(checkoutOrderSuccess)),
    map(action => push({ pathname: compiledPaths.CHECKOUT_SUCCESS({ id: action.payload.orders[0].order }) })),
  );
};

const setItemsFailureEpic: AppEpic = action$ => {
  return action$.pipe(
    filter(isActionOf(setItemsFailure)),
    mergeMap(() => {
      addToast(i18next.t('genericErrors:request_fail'));

      return EMPTY;
    }),
  );
};

const splitOrderCheckoutEpic: AppEpic = action$ => {
  return action$.pipe(
    filter(isActionOf(checkoutOrderSplitSuccess)),
    map(action => push({ pathname: compiledPaths.CHECKOUT_SUCCESS({ id: action.payload.originalOrderId }) })),
  );
};

const failedCheckoutEpic: AppEpic = action$ => {
  return action$.pipe(
    filter(isActionOf(checkoutOrderFailure)),
    map(() => push({ pathname: paths.CHECKOUT_FAILURE })),
  );
};

const addAllProductsWithFiltersEpic: AppEpic = (action$, state$, { ordersRepository }) => {
  return action$.pipe(
    filter(isActionOf(addAllProductsToOrderRequest)),
    mergeMap(async action => {
      const state = state$.value;
      const { filters } = action.payload;
      const isSeller = getIsSeller(state);
      const orderId = getOrderId(state);
      const buyerId = getBuyerIdFromCurrentOrder(state);
      const activeDeliveryWindows = getActiveDeliveryWindowsIdsForOrderBuyer(state);

      return ordersRepository.addAllProductsWithFilters({
        ...(isSeller ? { buyer: buyerId } : {}),
        activeDeliveryWindows,
        filterData: filters,
        orderId,
      });
    }),
    mapResponse(
      res => addAllProductsToOrderSuccess(res.data),
      () => addAllProductsToOrderFailure(),
    ),
  );
};

const invoicesOrderEpic: AppEpic = (action$, state$, { ordersRepository }) =>
  action$.pipe(
    filter(isActionOf(fetchInvoicesRequest)),
    filter(() => getIsLoggedIn(state$.value)),
    mergeMap(async action => ordersRepository.getOrderInvoices(action.payload)),
    mapResponse(
      res => fetchInvoicesSuccess(res.data),
      () => fetchInvoicesFailure(),
    ),
  );

const updateShippingAddressInOrderEpic: AppEpic = (action$, state$, { ordersRepository }) =>
  action$.pipe(
    filter(isActionOf(updateTotalsByShippingAddress)),
    mergeMap(async action => {
      const orderId = getOrderId(state$.value);

      return ordersRepository.updateShippingAddress(orderId, action.payload);
    }),
    mapResponse(
      res => {
        addToast(i18next.t('shipping:shipping_address_updated'));

        const orderDetails = getOrderDetails(state$.value);
        const shippingMethodsCount =
          (orderDetails.order.shippingMethods?.length ?? 0) || (orderDetails.splitOrders?.[0]?.shippingMethods?.length ?? 0);
        const updatedShippingMethodsCount =
          (res.data.order.shippingMethods?.length ?? 0) || (res.data.splitOrders?.[0]?.shippingMethods?.length ?? 0);

        if (updatedShippingMethodsCount < shippingMethodsCount) {
          addToast(i18next.t('shipping:shipping_methods_updated'));
        }

        return updateOrderByShippingAddressSuccess(res.data);
      },
      () => {
        addToast(i18next.t('shipping:shipping_address_update_fail'));

        return updateTotalsByShippingAddressFailure();
      },
    ),
  );

const setPrepacksEpic: AppEpic = (action$, state$, { ordersRepository }) =>
  action$.pipe(
    filter(isActionOf(setPrepacksRequest)),
    debounceTime(DEBOUNCE_TIME),
    switchMap(action => {
      const orderId = getOrderId(state$.value);

      return from(ordersRepository.setPrepacks(orderId, action.payload.data)).pipe(
        mapResponse(
          res => [setItemsSuccess(res.data)],
          () => setItemsFailure(),
        ),
        takeUntil(action$.pipe(filter(isActionOf(setPrepacksRequest)))),
      );
    }),
  );

const removePrepacksEpic: AppEpic = (action$, state$, { ordersRepository }) =>
  action$.pipe(
    filter(isActionOf(removePrepacksRequest)),
    debounceTime(DEBOUNCE_TIME),
    mergeMap(async action => {
      const orderId = getOrderId(state$.value);

      return ordersRepository.setPrepacks(orderId, { ...action.payload.data, prepacks: [] });
    }),
    mapResponse(
      () => EMPTY,
      () => setItemsFailure(),
    ),
  );

const fetchUserOrdersEpic: AppEpic = action$ =>
  action$.pipe(
    filter(isActionOf(authoriseAccessSuccess)),
    map(() => fetchOrdersRequest()),
  );

export const ordersEpic = combineEpics(
  checkoutOrderEpic,
  failedCheckoutEpic,
  splitOrderCheckoutEpic,
  successCheckoutEpic,
  addAllProductsWithFiltersEpic,
  invoicesOrderEpic,
  setPrepacksEpic,
  removePrepacksEpic,
  updateShippingAddressInOrderEpic,
  setItemsFailureEpic,
  fetchUserOrdersEpic,
);
