import { Addresses, Checkout, Filters, Id, Prepack, Product, Unit } from '@typings';

import { showroomApi } from '../services/api';
import { isDefined } from '../utils/is';
import { isEmpty } from '../utils/isEmpty';

interface AddProductsWithFilters {
  filterData: Partial<Filters>;
  orderId: string;
  activeDeliveryWindows: Id[];
  buyer?: Id; // do not send when logged in as a buyer
}

export const checkoutOrder = async (data: Checkout.Data, orderId: string) => {
  return await showroomApi.POST('/orders/:orderId/checkout')({ data, pathParams: { orderId } });
};

export const addAllProductsWithFilters = async (requestData: AddProductsWithFilters) => {
  const { filterData, orderId, activeDeliveryWindows, buyer } = requestData;
  const { stock, ...filters } = filterData;

  const deliveryWindows =
    isDefined(filters.deliveryWindows) && !isEmpty(filters.deliveryWindows) ? filters.deliveryWindows : activeDeliveryWindows;

  return showroomApi.POST('/orders/:orderId/products-by-filter')({
    data: { ...filters, allVariants: true, buyer, deliveryWindows } as unknown as Filters,
    pathParams: {
      orderId,
    },
  });
};

export const getOrderInvoices = async (orderId: string) => {
  return showroomApi.GET('/orders/:orderId/invoices')({ pathParams: { orderId } });
};

export const copyToNewSelectionRequest = async ({ name, orderId, buyer }: { name: string; orderId: string; buyer?: string }) => {
  return showroomApi.POST('/orders/:orderId/clone')({
    data: {
      buyer,
      name,
      order: orderId,
    },
    pathParams: { orderId },
  });
};

export const addProductsToOrder = async (orderId: Id, data: Product.AddProductPayload) =>
  showroomApi.POST('/orders/:orderId/items')({
    data: {
      deliveryWindows: data.deliveryWindows,
      order: orderId,
      products: data.products,
    },
    pathParams: { orderId },
  });

export const setQuantityForMultipleItems = async (orderId: Id, data: Unit.ItemsDTO) =>
  showroomApi.POST('/PUT/orders/:orderId/items')({
    data: {
      items: data,
      order: orderId,
      returnProducts: true,
    },
    pathParams: { orderId },
  });

export const setPrepacks = async (orderId: Id, data: Prepack.Data) =>
  showroomApi.PUT('/orders/:orderId/prepacks')({
    data,
    pathParams: { orderId },
  });

export const updateShippingAddress = async (orderId: Id, data: Addresses.ShippingAddressData) =>
  showroomApi.PUT('/orders/:orderId/shipping-address')({
    data,
    pathParams: { orderId },
  });

export const removeProductsFromOrder = async (orderId: string, { deliveryWindows, products, variants }: Product.RemovePayload) => {
  const data = {
    deliveryWindows,
    ...(isDefined(variants) ? { variants } : { products }),
  };

  return showroomApi.DELETE('/orders/:orderId/items')({
    data,
    pathParams: {
      orderId,
    },
  });
};

export const addVoucher = async (orderId: Id, voucher: string) =>
  showroomApi.POST('/orders/:orderId/vouchers')({
    data: { voucher },
    pathParams: { orderId },
  });

export const removeVoucher = async (orderId: Id, voucher: string) =>
  showroomApi.DELETE('/orders/:orderId/vouchers')({
    data: { voucher },
    pathParams: { orderId },
  });

export const updateOrderShipping = async (orderId: Id, data: { splitId: Id; shippingMethodId: Id }) =>
  showroomApi.QUERY_POST('/orders/:orderId/shipping-update')({
    data,
    pathParams: { orderId },
  });

export const getTermsAndConditions = async () => showroomApi.GET('/terms-and-conditions')({});
