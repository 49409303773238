import { Toast } from '@typings';
import cx from 'classnames';
import { useDispatch } from 'react-redux';

import { isDefined } from '../../../utils/is';
import Button from '../Button';
import Icon, { IconType } from '../Icon';

import styles from './Toaster.module.scss';

interface Props {
  handleClose: () => void;
  action: Toast.Action;
}

export const ToastActionButton = ({ action, handleClose }: Props) => {
  const dispatch = useDispatch();

  const handleActionClick = () => {
    const { shouldCloseAfterClick = true } = action;

    if (shouldCloseAfterClick) {
      handleClose();
    }

    action.onClick?.();

    if (!isDefined(action.action)) {
      return;
    }

    dispatch(action.action);
  };

  if (isDefined(action.variant)) {
    return (
      <button
        type="button"
        disabled={action.isLoading}
        className={cx(styles.actionButton, {
          [styles.actionButtonPrimary]: action.variant === 'primary',
          [styles.actionButtonLoading]: action.isLoading,
        })}
        onClick={handleActionClick}
      >
        {action.isLoading && <Icon type={IconType.Spinner} size={16} />}
        {action.label}
      </button>
    );
  }

  return (
    <Button onClick={handleActionClick} variant={['button', 'extraSmall', 'transparent']}>
      {action.label}
    </Button>
  );
};
