import { DeliveryWindow, Order, Product } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import universalStyles from '../../../../../css/utilities/universal.module.scss';
import { getActiveDeliveryWindowsIdsForOrderBuyer, getOrderCurrency, pushEvent } from '../../../../ducks';
import { getIsExpired } from '../../../../logic/deliveryWindows';
import { getIsOrderEditable, getTotalPriceInDelwin, getUnitsCountInDelwin, hasCancelledProducts } from '../../../../logic/Orders';
import { formatPriceWithCurrencyAffix } from '../../../../logic/price';
import { CheckoutTrackingEvent } from '../../../../utils/analytics/events';
import { useExpand } from '../../../../utils/hooks';
import { CancelledProductsTooltip } from '../../../various/CancelledProductsTooltip';
import { DelwinExpiredTooltip } from '../../../various/DelwinExpiredTooltip/DelwinExpiredTooltip';
import { DelwinNameAndDate } from '../../../various/DelwinNameAndDate';
import Icon, { IconColor, IconType } from '../../../various/Icon';
import { ProductDetailsShared } from '../../ProductDetailsShared';
import { ReceiptRow } from '../ReceiptRow';

import styles from './ExpandableDeliveryWindow.module.scss';

interface Props {
  deliveryWindow: DeliveryWindow.Mixed;
  order: Order.Open;
  products: never[] | Record<Product.Id, Product.Full>;
}

export const ExpandableProductOverview = ({ deliveryWindow, order, products }: Props) => {
  const activeDeliveryWindowsIds = useSelector(getActiveDeliveryWindowsIdsForOrderBuyer);
  const currency = useSelector(getOrderCurrency);
  const { t } = useTranslation(['common']);
  const expanderId = React.useId();
  const dispatch = useDispatch();
  const { isOpen, toggle, wrapperRef } = useExpand<HTMLDivElement>({
    initialOpen: false,
  });

  const handleExpandToggle = () => {
    if (!isOpen) {
      dispatch(pushEvent({ event: CheckoutTrackingEvent.EXPAND_DELIVERY_WINDOW_CLICKED }));
    }
    toggle();
  };
  const unitsInDeliveryWindow = getUnitsCountInDelwin(deliveryWindow.deliveryWindow, order.products);

  if (unitsInDeliveryWindow === 0) {
    return null;
  }

  const { deliveryWindow: delwinId } = deliveryWindow;
  const isExpired = getIsExpired(deliveryWindow, activeDeliveryWindowsIds);
  const isEditableOrder = getIsOrderEditable(order.status);

  const variantsInDelwin = order.products.filter(product => product.deliveryWindow === delwinId);
  const totalPrice = getTotalPriceInDelwin(delwinId, order.products);

  return (
    <>
      <ReceiptRow>
        <div className={styles.deliveryWindowNameWrapper}>
          <span className={styles.deliveryWindowName}>
            <DelwinNameAndDate deliveryWindow={deliveryWindow} iconClassName={styles.dateAlert} />
          </span>

          {isEditableOrder && (
            <div className={styles.alertWrapper}>
              {hasCancelledProducts(variantsInDelwin) && <CancelledProductsTooltip className={styles.alert} />}

              {isExpired && <DelwinExpiredTooltip className={styles.alert} />}
            </div>
          )}
        </div>

        <div>{unitsInDeliveryWindow}</div>

        <div>{formatPriceWithCurrencyAffix(currency)(totalPrice)}</div>

        <button
          type="button"
          onClick={handleExpandToggle}
          className={cx(styles.expandButton, universalStyles.overlayLink)}
          aria-label={t('common:expand_row')}
          aria-expanded={isOpen}
          aria-controls={expanderId}
        >
          <Icon type={IconType.ArrowBlackDown} color={IconColor.Medium} rotation={isOpen ? 180 : 0} />
        </button>
      </ReceiptRow>

      <div id={expanderId} ref={wrapperRef} className={cx(styles.productsContainer, { [styles.expanded]: isOpen })}>
        <ProductDetailsShared
          orderVariants={variantsInDelwin}
          order={order}
          products={products}
          delwinIds={[delwinId]}
          isExpired={isExpired}
        />
      </div>
    </>
  );
};
