import { Form } from '@typings';
import cx from 'classnames';
import React from 'react';

import { isDefined } from '../../../../../utils/is';

import { FieldDescription } from './FieldDescription';
import { FieldError } from './FieldError';
import { FieldLabel } from './FieldLabel';
import styles from './FormParts.module.scss';

export const FieldWrapper = (props: React.WithChildren<Form.FieldWrapperProps>) => {
  const {
    as,
    children,
    description,
    describedById,
    errorMessage,
    errorId,
    label,
    labelId,
    isRequired,
    helpMessage,
    orientation = 'vertical',
    size,
    fieldClassName,
  } = props;

  const hasError = isDefined(errorMessage) && isDefined(errorId);
  const hasDescription = isDefined(description) && isDefined(describedById);

  const classNames = cx({
    [styles.horizontal]: orientation === 'horizontal',
    [styles.auto]: orientation === 'auto',
    [styles.hasDescription]: isDefined(description),
  });

  const field = (
    <div className={cx(styles.field, fieldClassName)}>
      {children}
      {hasError && <FieldError id={errorId}>{errorMessage}</FieldError>}
    </div>
  );

  return (
    <div className={classNames}>
      {isDefined(label) ?
        <FieldLabel as={as} size={size} label={label} id={labelId} isRequired={isRequired} helpMessage={helpMessage}>
          {field}
        </FieldLabel>
      : field}
      {hasDescription && <FieldDescription id={describedById}>{description}</FieldDescription>}
    </div>
  );
};
