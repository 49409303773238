import { Order } from '@typings';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIsUpdatingShipping, updateShippingRequest } from '../../../../ducks';
import { useOrderShippingUpdate } from '../../../../services/hooks/orders/useOrderShippingUpdate';
import { useDebouncedCallback } from '../../../../utils/hooks';
import { RadioGroup } from '../../../various/Fields/RadioGroup';

import { CheckoutShippingMethodLabel } from './CheckoutShippingMethodLabel';
import styles from './CheckoutShippingMethods.module.scss';

interface Props {
  orderId: string;
  splitId?: string;
  methods: Order.ShippingMethod[];
  selectedMethods: Order.SelectedShippingMethod[];
}

const DEBOUNCE_DELAY = 500;

export const CheckoutShippingMethods = ({ orderId, splitId = orderId, methods, selectedMethods }: Props) => {
  const dispatch = useDispatch();
  const isUpdatingShipping = useSelector(getIsUpdatingShipping);

  const getSelectedMethodId = React.useCallback(() => {
    return selectedMethods.find(method => method.splitId === splitId)?.shippingMethodId;
  }, [selectedMethods, splitId]);

  const [selectedId, setSelectedId] = React.useState(getSelectedMethodId);

  React.useEffect(() => {
    if (!isUpdatingShipping) {
      setSelectedId(getSelectedMethodId());
    }
  }, [getSelectedMethodId, isUpdatingShipping]);

  const { mutate } = useOrderShippingUpdate();

  const options = methods.map(method => ({
    name: <CheckoutShippingMethodLabel key={method.id} method={method} />,
    value: method.id,
  }));

  const updateOrderShipping = useDebouncedCallback((methodId: string) => {
    mutate({ orderId, shippingMethodId: methodId, splitId });
  }, DEBOUNCE_DELAY);

  const handleChange = (methodId: string) => {
    setSelectedId(methodId);
    updateOrderShipping(methodId);
    dispatch(updateShippingRequest({ orderId: splitId }));
  };

  return (
    <RadioGroup
      name={`shippingMethod-${splitId}`}
      options={options}
      itemClassName={styles.methodWrapper}
      value={selectedId}
      onChange={handleChange}
    />
  );
};
