import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { pushEvent } from '../../../../ducks';
import {
  getIsLoadingOrderDetails,
  getIsSubmittingVoucher,
  getIsUpdatingShipping,
  getIsVoucherValid,
  getOrderDetails,
  getPaymentTerm,
} from '../../../../ducks/order';
import { CheckoutTrackingEvent } from '../../../../utils/analytics/events';
import { useAvailableOrderDetails } from '../../../../utils/hooks/useAvailableOrderDetails';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import Button from '../../../various/Button';
import Heading from '../../../various/Heading';
import { VoucherForm } from '../VoucherForm';

import styles from './CheckoutSubtotal.module.scss';
import { HighlightItem } from './HighlightItem';
import { StandardItem } from './StandardItem';
import { VoucherItem } from './VoucherItem';

interface Props {
  isReadOnly?: boolean;
}

export const CheckoutSubtotal = ({ isReadOnly = false }: Props) => {
  const { order } = useSelector(getOrderDetails);
  const { orderTotals } = useAvailableOrderDetails();
  const { discounts } = order ?? {};
  const vouchers = discounts?.vouchers ?? {};

  const { t } = useTranslation(['common', 'checkout', 'totals', 'shipping']);
  const dispatch = useDispatch();

  const isSubmittingVoucher = useSelector(getIsSubmittingVoucher);
  const isSubmittedVoucherValid = useSelector(getIsVoucherValid);
  const isUpdatingShipping = useSelector(getIsUpdatingShipping);
  const paymentTerm = useSelector(getPaymentTerm);
  const isLoadingOrderDetails = useSelector(getIsLoadingOrderDetails);
  const [isVoucherFormVisible, setIsVoucherFormVisible] = React.useState(false);

  const handleToggleVoucherForm = React.useCallback(() => {
    !isVoucherFormVisible && dispatch(pushEvent({ event: CheckoutTrackingEvent.ADD_VOUCHER_BUTTON_CLICKED }));
    setIsVoucherFormVisible(isFormVisible => !isFormVisible);
  }, [dispatch, isVoucherFormVisible]);

  React.useEffect(() => {
    if (isSubmittedVoucherValid && !isSubmittingVoucher) {
      setIsVoucherFormVisible(false);
    }
  }, [isSubmittedVoucherValid, isSubmittingVoucher]);

  const vouchersRows = React.useMemo(() => {
    if (isEmpty(vouchers)) {
      return [];
    }

    return Object.values(vouchers).map(voucher => ({
      id: voucher.voucher,
      label: t('checkout:voucher_discount'),
      type: 'voucher',
      value: voucher.priceOff,
    }));
  }, [t, vouchers]);

  const taxesRows = React.useMemo(() => {
    if (!isDefined(orderTotals?.taxes) || isEmpty(orderTotals.taxes)) {
      return [];
    }

    return orderTotals.taxes.map(tax => ({
      id: `tax${tax.taxPercent}`,
      label: t('common:tax_percent', { taxPercent: tax.taxPercent }),
      value: tax.totalPriceTax,
    }));
  }, [t, orderTotals]);

  const totalsRows = React.useMemo(
    () => [
      {
        id: 'subtotal',
        label: t('totals:subtotal'),
        value: orderTotals?.itemsTotalPrice,
      },
      ...vouchersRows,
      ...(orderTotals?.totalDiscountPrice ?
        [
          {
            id: 'totalDiscountPrice',
            label: t('totals:total_savings'),
            value: orderTotals?.totalDiscountPrice,
          },
        ]
      : []),
      {
        id: 'shippingPrice',
        label: t('shipping:shipping_cost'),
        value: orderTotals?.shippingPrice,
      },
      {
        id: 'grandTotalWithoutTax',
        label: t('totals:total_no_tax'),
        type: 'highlight',
        value: orderTotals?.grandTotalWithoutTax,
      },
      ...taxesRows,
      {
        id: 'grandTotalPrice',
        label: t('totals:grandtotal_with_tax'),
        value: orderTotals?.grandTotalPrice,
      },
      ...(isDefined(orderTotals?.prepaymentPriceAsText) && isDefined(paymentTerm) ?
        [
          {
            id: 'prepaymentPrice',
            label: t('totals:total_prepayment', { percent: paymentTerm.prepaymentPercent }),
            value: orderTotals.prepaymentPriceAsText,
          },
        ]
      : []),
    ],
    [paymentTerm, t, taxesRows, orderTotals, vouchersRows],
  );

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <Heading variant={['blue', 'h2']} title={t('totals:total_other')} />
        {!isReadOnly && (
          <Button disabled={isLoadingOrderDetails} onClick={handleToggleVoucherForm} variant={['simpleOrangeLink']}>
            {isVoucherFormVisible ? t('common:cancel') : t('checkout:voucher_add')}
          </Button>
        )}
      </div>
      {isVoucherFormVisible && <VoucherForm />}
      <dl className={styles.totals}>
        {totalsRows.map(({ type, value, label, id }) => {
          if (type === 'highlight') {
            return <HighlightItem key={id} label={label} value={value} isLoading={!isDefined(orderTotals) || isUpdatingShipping} />;
          }

          if (type === 'voucher') {
            return <VoucherItem key={id} label={label} value={value} id={id} isReadOnly={isReadOnly} />;
          }

          return <StandardItem key={id} label={label} value={value} isLoading={!isDefined(orderTotals) || isUpdatingShipping} />;
        })}
      </dl>
    </div>
  );
};
