import { Radio } from '@typings';
import cx from 'classnames';
import React from 'react';

import { isDefined } from '../../../../utils/is';
import Icon, { IconColor } from '../../Icon';

import styles from './RadioGroup.module.scss';

export const RadioGroup = <T extends Maybe<string>>({
  className,
  describedById,
  disabled = false,
  isRequired,
  itemClassName,
  labelledById,
  name,
  options,
  orientation = 'vertical',
  size = 'regular',
  value,
  onBlur,
  onChange,
}: Radio.Group<T>) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value as T);
  };

  const groupClassNames = cx(styles.group, className, {
    [styles.horizontal]: orientation === 'horizontal',
    [styles.small]: size === 'small',
  });

  return (
    <div role="radiogroup" aria-labelledby={labelledById} aria-describedby={describedById} className={groupClassNames}>
      {options.map((option, index) => {
        const isChecked = option.value === value;
        const isDisabled = disabled || option.disabled;
        const classNames = cx(styles.radio, itemClassName, {
          [styles.disabled]: isDisabled,
        });

        return (
          <label key={`${option.value}-${index}`} className={classNames}>
            <input
              type="radio"
              name={name}
              value={option.value ?? undefined}
              required={isRequired}
              className={styles.input}
              checked={isChecked}
              disabled={isDisabled}
              onBlur={onBlur}
              onChange={handleChange}
            />
            <span className={styles.name}>{option.name}</span>
            {isDefined(option.icon) && <Icon type={option.icon} color={IconColor.Gray} />}
          </label>
        );
      })}
    </div>
  );
};
