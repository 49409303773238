/* eslint-disable @typescript-eslint/no-shadow */
import { IconComponentProps } from '@typings';
import cx from 'classnames';
import React from 'react';

import { Account } from './Icons/Account';
import { Alert } from './Icons/Alert';
import { ArrowBlackDown } from './Icons/ArrowBlackDown';
import { ArrowDoubleLeft } from './Icons/ArrowDoubleLeft';
import { ArrowDown } from './Icons/ArrowDown';
import { ArrowLeft } from './Icons/ArrowLeft';
import { Arrows } from './Icons/Arrows';
import { ArrowSharp } from './Icons/ArrowSharp';
import { Attachment } from './Icons/Attachment';
import { BarChart } from './Icons/BarChart';
import { Barcode } from './Icons/Barcode';
import { Basket } from './Icons/Basket';
import { Calendar } from './Icons/Calendar';
import { Cancel } from './Icons/Cancel';
import { Chart } from './Icons/Chart';
import { Check } from './Icons/Check';
import { Checkout } from './Icons/Checkout';
import { Chevron } from './Icons/Chevron';
import { ChevronDown } from './Icons/ChevronDown';
import { CloseHamburger } from './Icons/CloseHamburger';
import { Accept } from './Icons/cms/Accept';
import { Add } from './Icons/cms/Add';
import { AddFilled } from './Icons/cms/AddFilled';
import { AddSquareOutline } from './Icons/cms/AddSquareOutline';
import { ArrowRounded } from './Icons/cms/ArrowRounded';
import { Button } from './Icons/cms/Button';
import { Clear } from './Icons/cms/Clear';
import { Close } from './Icons/cms/Close';
import { Cog } from './Icons/cms/Cog';
import { Cut } from './Icons/cms/Cut';
import { DeviceDesktop } from './Icons/cms/DeviceDesktop';
import { DeviceMobile } from './Icons/cms/DeviceMobile';
import { DeviceTablet } from './Icons/cms/DeviceTablet';
import { Download } from './Icons/cms/Download';
import { Drop } from './Icons/cms/Drop';
import { Duplicate } from './Icons/cms/Duplicate';
import { Edit } from './Icons/cms/Edit';
import { Embed } from './Icons/cms/Embed';
import { EmbedUrl } from './Icons/cms/EmbedUrl';
import { Home } from './Icons/cms/Home';
import { Hotspot } from './Icons/cms/Hotspot';
import { Image } from './Icons/cms/Image';
import { Layout } from './Icons/cms/Layout';
import { Link } from './Icons/cms/Link';
import { Market } from './Icons/cms/Market';
import { Media } from './Icons/cms/Media';
import { MoreHorizontal } from './Icons/cms/MoreHorizontal';
import { Move } from './Icons/cms/Move';
import { MoveDown } from './Icons/cms/MoveDown';
import { MoveUp } from './Icons/cms/MoveUp';
import { Overlay } from './Icons/cms/Overlay';
import { Reorder } from './Icons/cms/Reorder';
import { ReplaceHorizontal } from './Icons/cms/ReplaceHorizontal';
import { ReplaceVertical } from './Icons/cms/ReplaceVertical';
import { Reposition } from './Icons/cms/Reposition';
import { Reset } from './Icons/cms/Reset';
import { Resize } from './Icons/cms/Resize';
import { Revert } from './Icons/cms/Revert';
import { RoundedAdd } from './Icons/cms/RoundedAdd';
import { Swap } from './Icons/cms/Swap';
import { Tag } from './Icons/cms/Tag';
import { Text } from './Icons/cms/Text';
import { TextAdd } from './Icons/cms/TextAdd';
import {
  AlignCenter,
  AlignLeft,
  AlignRight,
  Capitalize,
  Dash,
  Italic,
  Lowercase,
  TextCenter,
  TextLeft,
  TextRight,
  Uppercase,
} from './Icons/cms/textTools';
import { Trash } from './Icons/cms/Trash';
import { TrashWithDetails } from './Icons/cms/TrashWithDetails';
import { Unlink } from './Icons/cms/Unlink';
import { Unpublish } from './Icons/cms/Unpublish';
import { UnpublishedPage } from './Icons/cms/UnpublishedPage';
import { Upload } from './Icons/cms/Upload';
import { Vimeo } from './Icons/cms/Vimeo';
import { Visibility } from './Icons/cms/Visibility';
import { YouTube } from './Icons/cms/YouTube';
import { Compose } from './Icons/Compose';
import { Copy } from './Icons/Copy';
import { CopyOutline } from './Icons/CopyOutline';
import { Cross } from './Icons/Cross';
import { DelwinPreorder } from './Icons/DelwinPreorder';
import { DelwinPreorderWithDates } from './Icons/DelwinPreorderWithDates';
import { DelwinStock } from './Icons/DelwinStock';
import { DelwinStockWithDates } from './Icons/DelwinStockWithDates';
import { DoubleCheck } from './Icons/DoubleCheck';
import { DownloadCloud } from './Icons/DownloadCloud';
import { DownloadLinesheet } from './Icons/DownloadLinesheet';
import { EditLinear } from './Icons/EditLinear';
import { Error } from './Icons/Error';
import { Eye } from './Icons/Eye';
import { EyeCrossed } from './Icons/EyeCrossed';
import { FileImport } from './Icons/FileImport';
import { FlagDe } from './Icons/flags/FlagDe';
import { FlagEn } from './Icons/flags/FlagEn';
import { FlagFr } from './Icons/flags/FlagFr';
import { FlagSv } from './Icons/flags/FlagSv';
import { Folder } from './Icons/Folder';
import { Gift } from './Icons/Gift';
import { GridView } from './Icons/GridView';
import { GroupByDelwin } from './Icons/GroupByDelwin';
import { Hamburger } from './Icons/Hamburger';
import { Help } from './Icons/Help';
import { HelpOutline } from './Icons/HelpOutline';
import { Hourglass } from './Icons/Hourglass';
import { ImagePlaceholder } from './Icons/ImagePlaceholder';
import { Info } from './Icons/Info';
import { Inventory } from './Icons/Inventory';
import { Language } from './Icons/Language';
import { List } from './Icons/List';
import { ListView } from './Icons/ListView';
import { Logout } from './Icons/Logout';
import { MoreVertical } from './Icons/MoreVertical';
import { Multiples } from './Icons/Multiples';
import { NoCamera } from './Icons/NoCamera';
import { Notepad } from './Icons/Notepad';
import { Openbook } from './Icons/Openbook';
import { OrderDetails } from './Icons/OrderDetails';
import { Overview } from './Icons/Overview';
import { Page } from './Icons/Page';
import { Party } from './Icons/Party';
import { Paste } from './Icons/Paste';
import { PieChart } from './Icons/PieChart';
import { Pin } from './Icons/Pin';
import { Play } from './Icons/Play';
import { Plus } from './Icons/Plus';
import { PlusCircle } from './Icons/PlusCircle';
import { PriceTag } from './Icons/PriceTag';
import { Products } from './Icons/Products';
import { Reload } from './Icons/Reload';
import { Search } from './Icons/Search';
import { Selection } from './Icons/Selection';
import { Settings } from './Icons/Settings';
import { Share } from './Icons/Share';
import { Spinner } from './Icons/Spinner';
import { Success } from './Icons/Success';
import { Synced } from './Icons/Synced';
import { Tools } from './Icons/Tools';
import { TranslationWarning } from './Icons/TranslationWarning';
import { TrashLinear } from './Icons/TrashLinear';
import { Unpin } from './Icons/Unpin';
import { User } from './Icons/User';
import { Users } from './Icons/Users';
import { Warning } from './Icons/Warning';
import styles from './Icon.module.scss';

export const enum IconType {
  Accept = 'accept',
  Account = 'account',
  Add = 'add',
  AddFilled = 'addFilled',
  AddSquareOutline = 'addSquareOutline',
  Alert = 'alert',
  AlignCenter = 'alignCenter',
  AlignLeft = 'alignLeft',
  AlignRight = 'alignRight',
  ArrowBlackDown = 'arrowBlackDown',
  ArrowDoubleLeft = 'arrowDoubleLeft',
  ArrowDown = 'arrowDown',
  ArrowLeft = 'arrowLeft',
  ArrowRounded = 'arrowRounded',
  ArrowSharp = 'arrowSharp',
  Arrows = 'arrows',
  Attachment = 'attachment',
  BarChart = 'barChart',
  Barcode = 'barcode',
  Basket = 'basket',
  Button = 'button',
  Calendar = 'calendar',
  Capitalize = 'capitalize',
  Chart = 'chart',
  Check = 'check',
  Checkout = 'checkout',
  Chevron = 'chevron',
  ChevronDown = 'chevronDown',
  Clear = 'clear',
  Close = 'close',
  CloseHamburger = 'closeHamburger',
  Cog = 'cog',
  Copy = 'copy',
  CopyOutline = 'copyOutline',
  Cut = 'cut',
  Reposition = 'reposition',
  Compose = 'compose',
  Cross = 'cross',
  Dash = 'dash',
  DelwinPreorder = 'delwinPreorder',
  DelwinPreorderWithDates = 'delwinPreorderWithDates',
  DelwinStock = 'delwinStock',
  DelwinStockWithDates = 'delwinStockWithDates',
  DeviceDesktop = 'deviceDesktop',
  DeviceTablet = 'deviceTablet',
  DeviceMobile = 'deviceMobile',
  DoubleCheck = 'doubleCheck',
  Download = 'download',
  DownloadCloud = 'downloadCloud',
  DownloadLinesheet = 'downloadLinesheet',
  Drop = 'drop',
  Duplicate = 'duplicate',
  Edit = 'edit',
  EditLinear = 'editLinear',
  Embed = 'embed',
  EmbedUrl = 'embedUrl',
  Error = 'error',
  Eye = 'eye',
  EyeCrossed = 'eyeCrossed',
  FileImport = 'fileImport',
  FlagDe = 'flagDe',
  FlagEn = 'flagEn',
  FlagFr = 'flagFr',
  FlagSv = 'flagSv',
  Folder = 'folder',
  Hotspot = 'hotspot',
  Image = 'image',
  ImagePlaceholder = 'imagePlaceholder',
  Italic = 'italic',
  Gift = 'gift',
  GridView = 'gridView',
  GroupByDelwin = 'groupByDelwin',
  Help = 'help',
  HelpOutline = 'helpOutline',
  Hamburger = 'hamburger',
  Hourglass = 'hourglass',
  Info = 'info',
  Inventory = 'inventory',
  Language = 'language',
  Layout = 'layout',
  Link = 'link',
  List = 'list',
  ListView = 'listView',
  Logout = 'logout',
  Lowercase = 'lowercase',
  Media = 'media',
  Cancel = 'cancel',
  MoreHorizontal = 'moreHorizontal',
  MoreVertical = 'moreVertical',
  Move = 'move',
  MoveDown = 'moveDown',
  MoveUp = 'moveUp',
  Multiples = 'multiples',
  OrderDetails = 'orderDetails',
  Openbook = 'openbook',
  Overlay = 'overlay',
  Overview = 'overview',
  Page = 'page',
  Party = 'party',
  Paste = 'paste',
  NoCamera = 'noCamera',
  Notepad = 'notepad',
  Play = 'play',
  Plus = 'plus',
  PlusCircle = 'plusCircle',
  PriceTag = 'priceTag',
  PieChart = 'pieChart',
  Pin = 'pin',
  Products = 'products',
  RoundedAdd = 'roundedAdd',
  Reload = 'reload',
  Reorder = 'reorder',
  ReplaceHorizontal = 'replaceHorizontal',
  ReplaceVertical = 'replaceVertical',
  Reset = 'reset',
  Resize = 'resize',
  Revert = 'revert',
  Search = 'search',
  Selection = 'selection',
  Settings = 'settings',
  Share = 'share',
  Spinner = 'spinner',
  Success = 'success',
  Swap = 'swap',
  Synced = 'synced',
  Tag = 'tag',
  Text = 'text',
  TextAdd = 'textAdd',
  Tools = 'tools',
  TranslationWarning = 'translationWarning',
  Trash = 'trash',
  TrashLinear = 'trashLinear',
  TrashWithDetails = 'trashWithDetails',
  TextCenter = 'textCenter',
  TextLeft = 'textLeft',
  TextRight = 'textRight',
  Unlink = 'unlink',
  Unpin = 'unpin',
  Unpublish = 'unpublish',
  UnpublishedPage = 'unpublishedPage',
  Upload = 'upload',
  Uppercase = 'uppercase',
  User = 'user',
  Users = 'users',
  Vimeo = 'vimeo',
  Visibility = 'visibility',
  Home = 'home',
  YouTube = 'youtube',
  Warning = 'warning',
  Market = 'market',
}

export const enum IconColor {
  Light = 'light',
  Medium = 'medium',
  Gray = 'gray',
  Dark = 'dark',
  Default = 'default',
  White = 'white',
  Blue = 'blue',
  Red = 'red',
  BermudaGray = 'bermudaGray',
  DarkGray = 'darkGray',
  BrightOrange = 'brightOrange',
  Faded = 'faded',
  DarkBlue = 'darkBlue',
  RomanRed = 'romanRed',
  Green = 'Green',
}

interface Props {
  className?: string;
  color?: IconColor;
  style?: React.CSSProperties;
  type: IconType;
  rotation?: number;
  size?: number;
  dataTestId?: string;
}

const icon: Record<IconType, { Icon: React.FC<IconComponentProps> } & IconComponentProps> = {
  accept: { Icon: Accept },
  account: { Icon: Account },
  add: { Icon: Add },
  addFilled: { Icon: AddFilled },
  addSquareOutline: { Icon: AddSquareOutline },
  alert: { Icon: Alert },
  alignCenter: { Icon: AlignCenter },
  alignLeft: { Icon: AlignLeft },
  alignRight: { Icon: AlignRight },
  arrowBlackDown: { Icon: ArrowBlackDown, hasStroke: true },
  arrowDoubleLeft: { Icon: ArrowDoubleLeft },
  arrowDown: { Icon: ArrowDown },
  arrowLeft: { Icon: ArrowLeft },
  arrowRounded: { Icon: ArrowRounded },
  arrowSharp: { Icon: ArrowSharp },
  arrows: { Icon: Arrows },
  attachment: { Icon: Attachment },
  barChart: { Icon: BarChart },
  barcode: { Icon: Barcode },
  basket: { Icon: Basket },
  button: { Icon: Button },
  calendar: { Icon: Calendar },
  cancel: { Icon: Cancel },
  capitalize: { Icon: Capitalize },
  chart: { Icon: Chart },
  check: { Icon: Check },
  checkout: { Icon: Checkout },
  chevron: { Icon: Chevron },
  chevronDown: { Icon: ChevronDown },
  clear: { Icon: Clear },
  close: { Icon: Close },
  closeHamburger: { Icon: CloseHamburger },
  cog: { Icon: Cog },
  compose: { Icon: Compose },
  copy: { Icon: Copy },
  copyOutline: { Icon: CopyOutline },
  cross: { Icon: Cross, hasStroke: true },
  cut: { Icon: Cut },
  dash: { Icon: Dash },
  delwinPreorder: { Icon: DelwinPreorder },
  delwinPreorderWithDates: { Icon: DelwinPreorderWithDates },
  delwinStock: { Icon: DelwinStock },
  delwinStockWithDates: { Icon: DelwinStockWithDates },
  deviceDesktop: { Icon: DeviceDesktop },
  deviceMobile: { Icon: DeviceMobile },
  deviceTablet: { Icon: DeviceTablet },
  doubleCheck: { Icon: DoubleCheck },
  download: { Icon: Download },
  downloadCloud: { Icon: DownloadCloud },
  downloadLinesheet: { Icon: DownloadLinesheet },
  drop: { Icon: Drop },
  duplicate: { Icon: Duplicate },
  edit: { Icon: Edit },
  editLinear: { Icon: EditLinear },
  embed: { Icon: Embed },
  embedUrl: { Icon: EmbedUrl },
  error: { Icon: Error },
  eye: { Icon: Eye },
  eyeCrossed: { Icon: EyeCrossed },
  fileImport: { Icon: FileImport },
  flagDe: { Icon: FlagDe },
  flagEn: { Icon: FlagEn },
  flagFr: { Icon: FlagFr },
  flagSv: { Icon: FlagSv },
  folder: { Icon: Folder },
  gift: { Icon: Gift },
  gridView: { Icon: GridView },
  groupByDelwin: { Icon: GroupByDelwin },
  hamburger: { Icon: Hamburger },
  help: { Icon: Help },
  helpOutline: { Icon: HelpOutline },
  home: { Icon: Home },
  hotspot: { Icon: Hotspot },
  hourglass: { Icon: Hourglass },
  image: { Icon: Image },
  imagePlaceholder: { Icon: ImagePlaceholder },
  info: { Icon: Info },
  inventory: { Icon: Inventory },
  italic: { Icon: Italic },
  language: { Icon: Language },
  layout: { Icon: Layout },
  link: { Icon: Link },
  list: { Icon: List },
  listView: { Icon: ListView },
  logout: { Icon: Logout },
  lowercase: { Icon: Lowercase },
  market: { Icon: Market },
  media: { Icon: Media },
  moreHorizontal: { Icon: MoreHorizontal },
  moreVertical: { Icon: MoreVertical },
  move: { Icon: Move },
  moveDown: { Icon: MoveDown },
  moveUp: { Icon: MoveUp },
  multiples: { Icon: Multiples },
  noCamera: { Icon: NoCamera },
  notepad: { Icon: Notepad },
  openbook: { Icon: Openbook },
  orderDetails: { Icon: OrderDetails },
  overlay: { Icon: Overlay },
  overview: { Icon: Overview },
  page: { Icon: Page },
  party: { Icon: Party },
  paste: { Icon: Paste },
  pieChart: { Icon: PieChart },
  pin: { Icon: Pin },
  play: { Icon: Play },
  plus: { Icon: Plus },
  plusCircle: { Icon: PlusCircle },
  priceTag: { Icon: PriceTag },
  products: { Icon: Products },
  reload: { Icon: Reload },
  reorder: { Icon: Reorder },
  replaceHorizontal: { Icon: ReplaceHorizontal },
  replaceVertical: { Icon: ReplaceVertical },
  reposition: { Icon: Reposition },
  reset: { Icon: Reset },
  resize: { Icon: Resize },
  revert: { Icon: Revert },
  roundedAdd: { Icon: RoundedAdd },
  search: { Icon: Search },
  selection: { Icon: Selection },
  settings: { Icon: Settings },
  share: { Icon: Share },
  spinner: { Icon: Spinner },
  success: { Icon: Success },
  swap: { Icon: Swap },
  synced: { Icon: Synced },
  tag: { Icon: Tag },
  text: { Icon: Text },
  textAdd: { Icon: TextAdd },
  textCenter: { Icon: TextCenter },
  textLeft: { Icon: TextLeft },
  textRight: { Icon: TextRight },
  tools: { Icon: Tools },
  translationWarning: { Icon: TranslationWarning },
  trash: { Icon: Trash },
  trashLinear: { Icon: TrashLinear },
  trashWithDetails: { Icon: TrashWithDetails },
  unlink: { Icon: Unlink },
  unpin: { Icon: Unpin },
  unpublish: { Icon: Unpublish },
  unpublishedPage: { Icon: UnpublishedPage },
  upload: { Icon: Upload },
  uppercase: { Icon: Uppercase },
  user: { Icon: User },
  users: { Icon: Users },
  vimeo: { Icon: Vimeo },
  visibility: { Icon: Visibility },
  warning: { Icon: Warning },
  youtube: { Icon: YouTube },
};

const Icon = ({ className, color, style, type, rotation, size, dataTestId }: Props) => {
  const colorClasses = cx({
    [styles.light]: color === IconColor.Light,
    [styles.medium]: color === IconColor.Medium,
    [styles.white]: color === IconColor.White,
    [styles.blue]: color === IconColor.Blue,
    [styles.red]: color === IconColor.Red,
    [styles.green]: color === IconColor.Green,
    [styles.romanRed]: color === IconColor.RomanRed,
    [styles.bermudaGray]: color === IconColor.BermudaGray,
    [styles.darkGray]: color === IconColor.DarkGray,
    [styles.faded]: color === IconColor.Faded,
    [styles.brightOrange]: color === IconColor.BrightOrange,
    [styles.gray]: color === IconColor.Gray,
    [styles.dark]: color === IconColor.Dark,
    [styles.darkBlue]: color === IconColor.DarkBlue,
    [styles.stroke]: icon[type]?.hasStroke,
  });

  const classNames = cx(colorClasses, styles.icon, className);
  const { Icon: IconComponent, ...iconProps } = icon[type];

  return (
    <span data-testid={dataTestId} className={classNames} style={{ ...style, transform: `rotate(${rotation}deg)` }}>
      <IconComponent width={size} {...iconProps} />
    </span>
  );
};

export default Icon;
