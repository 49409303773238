import { Checkout, CheckoutForm, DeliveryWindow, Order, ShippingDatesPreferences, ShippingDatesPreferencesItem } from '@typings';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { getCountryByShippingAddressId } from '../../logic/Orders';
import { isDefined } from '../../utils/is';
import { isEmpty } from '../../utils/isEmpty';

interface Props {
  deliveryWindows: (DeliveryWindow.Running | DeliveryWindow.Seasonal)[];
  checkoutFields: Checkout.Requirements;
  orderDetails: Order.Single;
}

export const useCheckoutFormValidationSchema = ({
  deliveryWindows,
  checkoutFields,
  orderDetails,
}: Props): yup.ObjectSchema<Partial<CheckoutForm>> => {
  const { t } = useTranslation(['validation']);

  const shippingDateSchema = yup.object<ShippingDatesPreferencesItem>().shape({
    ...(checkoutFields.cancelDateByDeliveryWindow && {
      cancelDate:
        checkoutFields.cancelDateByDeliveryWindow?.required ?
          yup.object<Dayjs>().required(t('validation:cancel_date_hint'))
        : yup.object<Dayjs>().nullable(),
    }),
    ...(checkoutFields.preferredShippingDateByDeliveryWindow && {
      preferredShippingDate:
        checkoutFields.preferredShippingDateByDeliveryWindow?.required ?
          yup.object<Dayjs>().required(t('validation:preferred_shipping_date_hint'))
        : yup.object<Dayjs>().nullable(),
    }),
  });

  const getPreferredShippingDateValidation = () => {
    return (
      !isDefined(checkoutFields.preferredShippingDateByDeliveryWindow) && {
        preferredShippingDate:
          checkoutFields.preferredShippingDate?.required ?
            yup.object<Dayjs>().required(t('validation:preferred_shipping_date_hint'))
          : yup.object<Dayjs>().nullable(),
      }
    );
  };

  const getCancelDateValidation = () => {
    return (
      !isDefined(checkoutFields.cancelDateByDeliveryWindow) && {
        cancelDate:
          checkoutFields.cancelDate?.required ?
            yup.object<Dayjs>().required(t('validation:cancel_date_hint'))
          : yup.object<Dayjs>().nullable(),
      }
    );
  };

  const getShippingDatesPreferencesValidation = () => {
    if (
      !isEmpty(deliveryWindows) &&
      (isDefined(checkoutFields.preferredShippingDateByDeliveryWindow) || isDefined(checkoutFields.cancelDateByDeliveryWindow))
    ) {
      return {
        shippingDatesPreferences: yup.object<ShippingDatesPreferences>(
          deliveryWindows.reduce((acc, delwin) => {
            return {
              ...acc,
              [delwin.deliveryWindow]: shippingDateSchema,
            };
          }, {}),
        ),
      };
    }

    return {};
  };

  const isTermsAndConditionsCheckedValidation = {
    isTermsAndConditionsChecked:
      checkoutFields.termsAndConditions?.required ? yup.bool().oneOf([true], t('validation:terms_conditions_hint')) : yup.bool().required(),
  };

  const getShippingAddressByIdValidation = () => {
    if (!isDefined(checkoutFields.shippingAddressId)) {
      return {};
    }

    return {
      shippingAddressId: yup.string().test('missingShippingCountry', t('validation:shipping_country_hint'), (addressId: string) => {
        const countryByShippingAddressId = getCountryByShippingAddressId(orderDetails.account?.shippingAddresses, addressId);

        return isDefined(countryByShippingAddressId) && !isEmpty(countryByShippingAddressId);
      }),
    };
  };

  const getPoNumberValidation = () => {
    return {
      poNumber: checkoutFields.poNumber?.required ? yup.string().required(t('validation:po_number_hint')) : yup.string(),
    };
  };

  return yup.object<Partial<CheckoutForm>>({
    ...getPoNumberValidation(),
    ...getShippingAddressByIdValidation(),
    ...getCancelDateValidation(),
    ...getPreferredShippingDateValidation(),
    ...getShippingDatesPreferencesValidation(),
    ...isTermsAndConditionsCheckedValidation,
  });
};
