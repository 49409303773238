import { Order } from '@typings';

import styles from './CheckoutShippingMethods.module.scss';

interface Props {
  method: Order.ShippingMethod;
}

export const CheckoutShippingMethodLabel = ({ method }: Props) => {
  return (
    <div className={styles.method}>
      <div className={styles.name}>{method.name}</div>
      <div className={styles.price}>{method.totals.price}</div>
    </div>
  );
};
