import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { addToast } from '../../../components/various/Toasts';
import { REDESIGN_NOTIFICATION_EXPIRATION_DATE_KEY } from '../../../constants/localStorage';
import { getIsLoggedIn } from '../../../ducks';
import { getIsFeatureEnabled } from '../../getIsFeatureEnabled';
import { isDefined } from '../../is';
import { useLocalStorage } from '../useLocalStorage';
import { useSwitchToRedesign } from '../useSwitchToRedesign';

const NOTIFICATION_KEY = 'redesignNotification';
const DISMISS_EXPIRATION_TIME = 7 * 24 * 60 * 60 * 1000;
const ACCEPT_EXPIRATION_TIME = 365 * 24 * 60 * 60 * 1000;

export const useRedesignNotification = () => {
  const { t } = useTranslation(['onboarding']);

  const isUserLoggedIn = useSelector(getIsLoggedIn);
  const isRedesignEnabled = getIsFeatureEnabled('ENABLE_REDESIGN');
  const switchToRedesign = useSwitchToRedesign();

  const [expireAt, setExpireAt] = useLocalStorage<Nullable<string>>({
    defaultValue: null,
    key: REDESIGN_NOTIFICATION_EXPIRATION_DATE_KEY,
  });

  const isNotificationDismissed = React.useMemo(() => {
    if (!isDefined(expireAt)) {
      return false;
    }

    return new Date(parseInt(expireAt, 10)).getTime() > Date.now();
  }, [expireAt]);

  const handleDismiss = React.useCallback(() => {
    setExpireAt((Date.now() + DISMISS_EXPIRATION_TIME).toString());
  }, [setExpireAt]);

  const handleAccept = React.useCallback(() => {
    setExpireAt((Date.now() + ACCEPT_EXPIRATION_TIME).toString());
    switchToRedesign();
    addToast(t('onboarding:redesign_notification.title'), {
      actions: [
        {
          isLoading: true,
          label: t('onboarding:redesign_notification.actions.accept'),
          onClick: handleAccept,
          variant: 'primary',
        },
        { label: t('onboarding:redesign_notification.actions.dismiss'), onClick: handleDismiss, variant: 'secondary' },
      ],
      id: NOTIFICATION_KEY,
    });
  }, [handleDismiss, setExpireAt, switchToRedesign, t]);

  React.useEffect(() => {
    if (isNotificationDismissed || !isUserLoggedIn || !isRedesignEnabled) {
      return;
    }

    addToast(t('onboarding:redesign_notification.title'), {
      actions: [
        {
          label: t('onboarding:redesign_notification.actions.accept'),
          onClick: handleAccept,
          shouldCloseAfterClick: false,
          variant: 'primary',
        },
        { label: t('onboarding:redesign_notification.actions.dismiss'), onClick: handleDismiss, variant: 'secondary' },
      ],
      description: t('onboarding:redesign_notification.description'),
      duration: 0,
      id: NOTIFICATION_KEY,
      onClose: handleDismiss,
    });
  }, [handleDismiss, isNotificationDismissed, isRedesignEnabled, isUserLoggedIn, handleAccept, t]);
};
