import { useMutation } from '@tanstack/react-query';
import { Id } from '@typings';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { addToast } from '../../../components/various/Toasts';
import { updateShippingFailure, updateShippingSuccess } from '../../../ducks';
import { updateOrderShipping } from '../../ordersRepository';

export const ORDER_SHIPPING_UPDATE_MUTATION_KEY = 'updateOrderShipping';

interface MutationVariables {
  orderId: Id;
  splitId: Id;
  shippingMethodId: Id;
}

export const useOrderShippingUpdate = () => {
  const { t } = useTranslation(['shipping']);
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: async ({ orderId, splitId, shippingMethodId }: MutationVariables) =>
      updateOrderShipping(orderId, { shippingMethodId, splitId }),
    mutationKey: [ORDER_SHIPPING_UPDATE_MUTATION_KEY],
    onError: (_, { splitId }) => {
      addToast(t('shipping:shipping_update_failure'));
      dispatch(updateShippingFailure({ orderId: splitId }));
    },
    onSuccess: (orderDetails, { splitId }) => {
      dispatch(updateShippingSuccess({ ...orderDetails, orderId: splitId }));
    },
  });
};
