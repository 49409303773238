import { Checkout, CheckoutForm, DeliveryWindow } from '@typings';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getShippingDateBy } from '../../../logic/deliveryWindows';
import { isDefined } from '../../../utils/is';
import { DelwinNameAndDate } from '../../various/DelwinNameAndDate';
import { DatePickerField, FormGrid } from '../../various/Form';
import Heading from '../../various/Heading';
import { Skeleton } from '../../various/Skeleton';
import { SkeletonLayout } from '../../various/SkeletonLayout';

import styles from './DelwinDates.module.scss';

interface Props {
  checkoutFields: Checkout.Requirements;
  deliveryWindows: DeliveryWindow.Mixed[];
  isLoading: boolean;
}

const getDelwinHeading = (delwin: DeliveryWindow.Mixed) => <DelwinNameAndDate deliveryWindow={delwin} />;

export const DelwinDates = ({ checkoutFields, deliveryWindows, isLoading }: Props) => {
  const hasMultipleDates =
    isDefined(checkoutFields.preferredShippingDateByDeliveryWindow) || isDefined(checkoutFields.cancelDateByDeliveryWindow);

  const { t } = useTranslation(['checkout', 'shipping']);
  const {
    watch,
    formState: { errors },
  } = useFormContext<CheckoutForm>();
  const [shippingDatesPreferences, cancelDateFieldValue, preferredShippingDateFieldValue] = watch([
    'shippingDatesPreferences',
    'cancelDate',
    'preferredShippingDate',
  ]);

  const getCancelDateMin = (deliveryWindow: DeliveryWindow.Mixed) => {
    const shippingDate =
      hasMultipleDates ? shippingDatesPreferences?.[deliveryWindow.deliveryWindow]?.preferredShippingDate : preferredShippingDateFieldValue;

    return shippingDate?.add(1, 'day') ?? getShippingDateBy('startDate', deliveryWindow);
  };

  const getShippingDateMax = (deliveryWindow: DeliveryWindow.Mixed) => {
    const cancelDate = hasMultipleDates ? shippingDatesPreferences?.[deliveryWindow.deliveryWindow]?.cancelDate : cancelDateFieldValue;

    return cancelDate?.subtract(1, 'day') ?? getShippingDateBy('endDate', deliveryWindow);
  };

  const hasAnyPreferredShippingDate =
    isDefined(checkoutFields.preferredShippingDate) || isDefined(checkoutFields.preferredShippingDateByDeliveryWindow);
  const hasAnyCancelShippingDate = isDefined(checkoutFields.cancelDate) || isDefined(checkoutFields.cancelDateByDeliveryWindow);

  const isPreferredShippingDateByDeliveryWindowRequired =
    isDefined(checkoutFields.preferredShippingDateByDeliveryWindow) && checkoutFields.preferredShippingDateByDeliveryWindow.required;
  const isPreferredShippingDateRequired = isDefined(checkoutFields.preferredShippingDate) && checkoutFields.preferredShippingDate.required;
  const isAnyPreferredShippingDateRequired = isPreferredShippingDateByDeliveryWindowRequired || isPreferredShippingDateRequired;

  const isCancelDateByDeliveryWindowRequired =
    isDefined(checkoutFields.cancelDateByDeliveryWindow) && checkoutFields.cancelDateByDeliveryWindow.required;
  const isCancelDateRequired = isDefined(checkoutFields.cancelDate) && checkoutFields.cancelDate.required;
  const isAnyCancelDateRequired = isCancelDateByDeliveryWindowRequired || isCancelDateRequired;

  const getPreferredShippingDateName = (deliveryWindow: DeliveryWindow.Mixed) => {
    return hasMultipleDates ?
        (`shippingDatesPreferences.${deliveryWindow.deliveryWindow}.preferredShippingDate` as const)
      : ('preferredShippingDate' as const);
  };

  const getCancelShippingDateName = (deliveryWindow: DeliveryWindow.Mixed) => {
    return hasMultipleDates ? (`shippingDatesPreferences.${deliveryWindow.deliveryWindow}.cancelDate` as const) : ('cancelDate' as const);
  };

  const [firstDeliveryWindow] = deliveryWindows;
  const nonSplitDeliveryWindows: DeliveryWindow.Mixed[] = isDefined(firstDeliveryWindow) ? [firstDeliveryWindow] : [];

  const deliveryWindowsBasedOnConfig = hasMultipleDates ? deliveryWindows : nonSplitDeliveryWindows;

  const placeholderDeliveryWindow = [
    {
      deliveryWindow: '0',
    },
  ] as DeliveryWindow.Mixed[];

  const deliveryWindowsToShow = isLoading ? placeholderDeliveryWindow : deliveryWindowsBasedOnConfig;

  const hasPreferredShippingDateErrors =
    isDefined(errors.preferredShippingDate) ||
    (isDefined(errors.shippingDatesPreferences) &&
      isDefined(Object.values(errors.shippingDatesPreferences).find(error => error?.preferredShippingDate)));

  return (
    <div>
      <Heading variant={['blue', 'h2']} title={t('shipping:shipping_preferences')} />
      <div className={styles.wrapper}>
        {deliveryWindowsToShow.map((delwin, index) => (
          <div className={styles.delwinInfo} key={delwin.deliveryWindow}>
            <SkeletonLayout skeleton={<Skeleton type="text" size="xlarge" />} isLoading={isLoading}>
              <Heading className={styles.name} variant={['h3']} title={getDelwinHeading(delwin)} />
            </SkeletonLayout>

            <FormGrid className={styles.delwinField} rowGap={hasPreferredShippingDateErrors ? 'xlarge' : undefined}>
              {hasAnyPreferredShippingDate && (
                <DatePickerField<CheckoutForm>
                  name={getPreferredShippingDateName(delwin)}
                  label={`${t('shipping:pref_shipping_date')}:`}
                  isRequired={isAnyPreferredShippingDateRequired}
                  dataTestId="shipping-date-picker"
                  inputDataTestId={`shipping-date-picker-input-${index}`}
                  min={getShippingDateBy('startDate', delwin)}
                  max={getShippingDateMax(delwin)}
                  isLoading={isLoading}
                />
              )}

              {hasAnyCancelShippingDate && (
                <DatePickerField<CheckoutForm>
                  name={getCancelShippingDateName(delwin)}
                  label={`${t('checkout:cancel_date')}:`}
                  isRequired={isAnyCancelDateRequired}
                  dataTestId="cancel-date-picker"
                  inputDataTestId={`cancel-date-picker-input-${index}`}
                  min={getCancelDateMin(delwin)}
                  isLoading={isLoading}
                />
              )}
            </FormGrid>
          </div>
        ))}
      </div>
    </div>
  );
};
