import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { addToast } from '../../../components/various/Toasts';
import { deleteMultipleSelections } from '../../selectionRepository';

import { SELECTIONS_QUERY_KEY } from './useSelectionsList';

export const DELETE_SELECTIONS_MUTATION_KEY = 'deleteSelections';

export const useDeleteSelections = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(['orders']);
  const deleteSelectionByIds = useMutation({
    mutationFn: deleteMultipleSelections,
    mutationKey: [DELETE_SELECTIONS_MUTATION_KEY],
    onError: () => addToast(t('orders:orders_deletion_hint')),
    onSuccess: async () => queryClient.resetQueries({ queryKey: [SELECTIONS_QUERY_KEY] }),
  });

  return { deleteSelectionByIds };
};
