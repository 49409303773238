import React from 'react';

import { REDESIGN_ONBOARDING_MODAL } from '../../constants/localStorage';

import { useLocalStorage } from './useLocalStorage';

export const useSwitchToRedesign = () => {
  const [_, setShouldShowRedesignOnboarding] = useLocalStorage<string>({
    defaultValue: 'false',
    key: REDESIGN_ONBOARDING_MODAL,
  });

  return React.useCallback(() => {
    // eslint-disable-next-line functional/immutable-data, @microsoft/sdl/no-cookies
    document.cookie = 'app-version=new; path=/; max-age=15782016';
    window.location.reload();
    setShouldShowRedesignOnboarding('true');
  }, [setShouldShowRedesignOnboarding]);
};
