import { User } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useMatch } from 'react-router-dom';

import universalStyles from '../../../css/utilities/universal.module.scss';
import { getIsUserKnown } from '../../ducks';
import { getVersions } from '../../ducks/config';
import { getIsMenuOpened, toggleMenu } from '../../ducks/ui';
import { getIsMenuItemActive } from '../../logic/navigation';
import UserLogic from '../../logic/User';
import { paths } from '../../paths';
import { getIsFeatureEnabled } from '../../utils/getIsFeatureEnabled';
import { usePageScrolling } from '../../utils/hooks';
import { useSwitchToRedesign } from '../../utils/hooks/useSwitchToRedesign';
import { isDefined } from '../../utils/is';
import Icon, { IconType } from '../various/Icon';
import { Link } from '../various/Link';
import { Logo, LogoType } from '../various/Logo';
import { TextEllipsis } from '../various/TextEllipsis';

import styles from './Menu.module.scss';
import { SideNavigation } from './SideNavigation';

interface Props {
  user: User | Empty.Object;
  lookbookEnabled: boolean;
}

interface MenuItem {
  icon: IconType;
  iconSize?: number;
  isVisible: boolean;
  link: string;
  name: string;
  id?: string;
  isExternal?: boolean;
}

export const Menu = (props: Props) => {
  const { user, lookbookEnabled } = props;
  const { t } = useTranslation(['common', 'accounts', 'orders', 'selections', 'onboarding', 'translations']);
  const { setPageScrollable } = usePageScrolling();
  const dispatch = useDispatch();

  const appVersion = useSelector(getVersions);
  const isMenuOpened = useSelector(getIsMenuOpened);
  const { pathname, key } = useLocation();
  const isViewer = UserLogic.getIsViewer(user);
  const isBuyer = getIsUserKnown(user) && UserLogic.getIsBuyer(user);
  const isSeller = getIsUserKnown(user) && UserLogic.getIsSeller(user);
  const isAdmin = getIsUserKnown(user) && UserLogic.getIsAdmin(user);
  const isRedesignEnabled = getIsFeatureEnabled('ENABLE_REDESIGN');
  const isRootPage = useMatch(paths.ROOT)?.pattern.end ?? false;

  const [isSwitchButtonLoading, setIsSwitchButtonLoading] = React.useState(false);

  const switchToRedesign = useSwitchToRedesign();

  const handleSwitchButtonClick = () => {
    setIsSwitchButtonLoading(true);
    switchToRedesign();
  };

  React.useEffect(() => {
    if (!isMenuOpened) {
      return;
    }

    setPageScrollable(false);

    return () => {
      setPageScrollable(true);
    };
  }, [isMenuOpened]);

  const handleClose = React.useCallback(() => dispatch(toggleMenu(false)), []);

  React.useEffect(() => {
    if (isMenuOpened) {
      dispatch(toggleMenu(false));
    }
  }, [key]);

  const menuItems: MenuItem[] = React.useMemo(
    () => [
      {
        icon: IconType.Page,
        id: 'selectionsMenuLink',
        isVisible: true,
        link: paths.SELECTIONS,
        name: t('selections:selection_other'),
      },
      {
        icon: IconType.List,
        id: 'ordersMenuLink',
        isVisible: true,
        link: paths.ORDERS,
        name: t('orders:order_other'),
      },
      {
        icon: IconType.Notepad,
        id: 'invoicesMenuLink',
        isVisible: isBuyer,
        link: paths.INVOICES,
        name: t('common:invoice_other'),
      },
      {
        icon: IconType.Openbook,
        isVisible: isSeller && lookbookEnabled,
        link: paths.LOOKBOOK,
        name: t('common:lookbook'),
      },
      {
        icon: IconType.Users,
        isVisible: isSeller,
        link: paths.ACCOUNTS,
        name: t('accounts:account_other'),
      },
      {
        icon: IconType.Tools,
        isVisible: isSeller,
        link: paths.LINESHEETS,
        name: t('common:linesheet_other'),
      },
      {
        icon: IconType.Compose,
        isVisible: isAdmin,
        link: paths.PAGES_LIST,
        name: t('common:page_other'),
      },
      {
        icon: IconType.Settings,
        isVisible: isAdmin,
        link: paths.SETTINGS,
        name: t('common:settings'),
      },
      {
        icon: IconType.Language,
        isVisible: isAdmin,
        link: paths.TRANSLATIONS,
        name: t('translations:translations'),
      },
      {
        icon: IconType.Help,
        iconSize: 17,
        isExternal: true,
        isVisible: !isBuyer,
        link: paths.SUPPORT,
        name: t('common:support'),
      },
    ],
    [t, isSeller, lookbookEnabled, isAdmin, isBuyer],
  );

  return (
    <>
      {isMenuOpened && <button className={styles.block} onClick={handleClose} aria-label={t('common:close')} />}
      <div className={cx(styles.containerMenu, { [styles.isOpened]: isMenuOpened })}>
        <div className={styles.menu}>
          <div className={cx(styles.mainMenuContainer, { [styles.forceVisibility]: isRootPage })}>
            <SideNavigation />
          </div>
          {!isViewer && (
            <>
              <div className={styles.menuSectionName} aria-hidden>
                {t('common:system')}
              </div>
              <nav>
                <h2 className={universalStyles.srOnly}>{t('common:system_menu')}</h2>
                <ul>
                  {menuItems.map(
                    ({ isVisible, id, link, isExternal, icon, iconSize, name }) =>
                      isVisible && (
                        <li key={link}>
                          <Link
                            id={id}
                            external={isExternal}
                            to={link}
                            className={cx(styles.menuItem, { [styles.active]: getIsMenuItemActive(link, pathname) })}
                            target={isExternal ? '_blank' : undefined}
                          >
                            <span className={styles.icon}>
                              <Icon type={icon} size={iconSize} />
                            </span>
                            <TextEllipsis>{name}</TextEllipsis>
                          </Link>
                        </li>
                      ),
                  )}
                </ul>
              </nav>
              {isRedesignEnabled && (
                <button
                  type="button"
                  disabled={isSwitchButtonLoading}
                  className={cx(styles.switchButton, { [styles.switchButtonLoading]: isSwitchButtonLoading })}
                  onClick={handleSwitchButtonClick}
                >
                  <Icon type={isSwitchButtonLoading ? IconType.Spinner : IconType.Party} size={16} />
                  {t('onboarding:switch_to_redesign')}
                </button>
              )}
            </>
          )}
        </div>
        <div className={styles.powered}>
          <div className={styles.by}>
            {t('common:powered_by')}
            <Logo type={LogoType.Centra} size={52} className={styles.centraLogo} />
          </div>
          {isDefined(appVersion.api) ?
            <span className={styles.version}>
              v{appVersion.app} (API v{appVersion.api})
            </span>
          : <span className={styles.version}>v{appVersion.app}</span>}
        </div>
      </div>
    </>
  );
};
