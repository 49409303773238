import {
  ActionHandler,
  Category,
  Checkout,
  CheckoutForm,
  Currency,
  DeliveryWindow,
  FailedProducts,
  Fetchable,
  Id,
  Invoice,
  Items,
  Order,
  Product,
  Unit,
  Variants,
} from '@typings';
import { omit, uniq, without } from 'ramda';
import { createReducer } from 'typesafe-actions';

import { extractShippingAddressIdFromOrderResponse, getIsOrderSplit } from '../../logic/Orders';
import { isDefined } from '../../utils/is';
import { isEmpty } from '../../utils/isEmpty';
import { report } from '../../utils/monitoring';
import { update } from '../../utils/update';
import { selectionImportSuccess } from '../selectionImport';
import { sharedSelectionFailure } from '../selections/actions';
import { fetchUserSuccess, logoutRequest } from '../user/actions';

import {
  addAllProductsToOrderSuccess,
  addProductsToOrderSuccess,
  addShippingAddress,
  addVoucherFailure,
  addVoucherRequest,
  addVoucherSuccess,
  checkoutOrderFailure,
  checkoutOrderRequest,
  checkoutOrderSplitSuccess,
  checkoutOrderSuccess,
  checkoutOrderUnexpectedFailure,
  clearFailedProducts,
  copyToNewSelectionSuccess,
  createSelectionSuccess,
  editSelectionSuccess,
  fetchInvoicesSuccess,
  fetchOrderFailure,
  fetchOrderRequest,
  fetchOrderSuccess,
  loadInitialOrderDetails,
  loadTermsSuccess,
  removePrepacksRequest,
  removeProductsFromOrderSuccess,
  removeVoucherSuccess,
  resetCheckoutForm,
  resetCheckoutSuccess,
  resetOrderData,
  setActiveOrderPaymentMethod,
  setIsRequestingSplitOrder,
  setItemsFailure,
  setItemsRequest,
  setItemsSuccess,
  setMultipleItemsRequest,
  setPrepacksRequest,
  updateCheckoutForm,
  updateDefaultShippingAddress,
  updateOrderByShippingAddressSuccess,
  updateShippingFailure,
  updateShippingRequest,
  updateShippingSuccess,
} from './actions';

export interface OrderReducer {
  blockIfUnpaidInvoices: boolean;
  checkoutForm?: CheckoutForm;
  hasUnpaidInvoices: boolean;
  categories: Fetchable<Category[]>;
  failedOrder: Nullable<Checkout.Failure>;
  failedProducts: Nullable<{
    products: Record<Product.Id, Product.Full>;
    failedProducts: FailedProducts.ItemsMissing[];
  }>;
  invoices: Invoice.Standard[];
  isCheckoutSuccess: boolean;
  isRequestingSplitOrder: boolean;
  pendingShipmentUpdates: Order.Id[];
  isLoading?: boolean;
  isSubmitting: boolean;
  optimisticUpdates: Record<Product.Id, Record<Items.Id, number>>;
  order: Fetchable<Order.Single>;
  paymentMethod: Nullable<string>;
  initialSelectedShippingAddressId: Nullable<string>;
  termsText: string;
  voucher: {
    isValid: boolean;
    isSubmitting: boolean;
  };
  checkoutSplit: {
    currency: Currency;
    orders: Empty.Array | Tuple<Order.Essentials> | Tuple<Order.Closed>;
    originalOrderId: string;
  };
}

export const initialOrderData: Order.Single = {
  buyer: {
    buyer: '',
  },
  currency: {
    currency: '',
    decimalDigits: 0,
    decimalPoint: '.',
    name: '',
    prefix: '',
    suffix: '',
  },
  order: {
    currency: '',
    deliveryWindows: [],
    discounts: {},
    order: null,
    products: [],
    shippingAddress: {},
    totals: {},
  },
  orders: null,
  products: {},
} as any;

const initialState: OrderReducer = {
  blockIfUnpaidInvoices: false,
  categories: {
    data: [],
    isFailed: false,
    isLoading: false,
  },
  checkoutSplit: {
    currency: {
      currency: '',
      decimalDigits: 0,
      decimalPoint: '.',
      name: '',
      prefix: '',
      suffix: '',
    },
    orders: [],
    originalOrderId: '',
  },
  failedOrder: null,
  failedProducts: null,
  hasUnpaidInvoices: false,
  initialSelectedShippingAddressId: null,
  invoices: [],
  isCheckoutSuccess: false,
  isRequestingSplitOrder: false,
  isSubmitting: false,
  optimisticUpdates: {},
  order: {
    data: initialOrderData,
    isFailed: false,
    isLoading: false,
  },
  paymentMethod: null,
  pendingShipmentUpdates: [],
  termsText: '',
  voucher: {
    isSubmitting: false,
    isValid: true,
  },
};

const handleOrderRequest = (state: OrderReducer): OrderReducer =>
  update(state, {
    order: update(state.order, {
      data: initialOrderData,
      isLoading: true,
    }),
  });

/**
 * @deprecated
 * Orders are different when and closed. We're saving `undefined` for open ones.
 * `Order.Open & Order.Closed` is used instead of `Order.Open | Order.Closed`.
 * Ask backend team to send us the same order whether it's closed or not.
 *
 * @deprecated
 * Data per-delivery-window are available only for multi delwin setups.
 */
const setOrderSuccess: ActionHandler<OrderReducer, typeof fetchOrderSuccess | typeof updateShippingSuccess> = (state, { payload }) => {
  const isSplitOrder = getIsOrderSplit(payload);
  const initialSelectedShippingAddressId = extractShippingAddressIdFromOrderResponse(payload);

  if (!isSplitOrder) {
    const { buyer } = payload;

    return update(state, {
      blockIfUnpaidInvoices: buyer?.blockIfUnpaidInvoices ?? false,
      checkoutSplit: initialState.checkoutSplit,
      hasUnpaidInvoices: buyer?.hasUnpaidInvoices ?? false,
      initialSelectedShippingAddressId,
      isRequestingSplitOrder: false,
      order: update(state.order, {
        data: payload,
        isFailed: false,
        isLoading: false,
      }),
    });
  }

  if (isSplitOrder) {
    const { orders, currency } = payload;

    return update(state, {
      checkoutSplit: {
        currency,
        orders,
        originalOrderId: orders[0].originalOrder,
      },
      isRequestingSplitOrder: true,
      order: update(state.order, {
        isFailed: false,
        isLoading: false,
      }),
    });
  }

  return state;
};

const handleOrderFailure = (state: OrderReducer): OrderReducer =>
  update(state, {
    order: update(state.order, {
      isFailed: true,
      isLoading: false,
    }),
  });

const handleCreateSelectionSuccess: ActionHandler<OrderReducer, typeof createSelectionSuccess> = (state, action) => {
  const initialSelectedShippingAddressId = extractShippingAddressIdFromOrderResponse(action.payload);

  const buyerData =
    getIsOrderSplit(action.payload) ?
      {
        // optional buyer should be removed after backend fix
        blockIfUnpaidInvoices: action.payload.buyer?.blockIfUnpaidInvoices ?? false,
        hasUnpaidInvoices: action.payload.buyer?.hasUnpaidInvoices ?? false,
      }
    : {};

  return update(state, {
    initialSelectedShippingAddressId,
    order: {
      data: action.payload,
      isFailed: false,
      isLoading: false,
    },
    ...buyerData,
  });
};
const handleEditSelectionSuccess: ActionHandler<OrderReducer, typeof editSelectionSuccess> = (state, action) =>
  update(state, {
    order: {
      data: action.payload!,
      isFailed: false,
      isLoading: false,
    },
  });

// eslint-disable-next-line no-warning-comments
/**
 * When selecting units in the matrix from product details, if the product hasn't been added to order yet, we need to make sure
 * that we don't erase the value from the matrix with this response, since it's not aware of the optimistic update performed before,
 * meaning the previous value typed.
 * Even though the response from SET_ITEMS_SUCCESS (coming later) restores back the value, it's annoying to see that flicker during 0.5s/1s
 */
const handleAddProductsToOrderSuccess: ActionHandler<OrderReducer, typeof addProductsToOrderSuccess> = (state, action) => {
  const data = action.payload!;
  const { order } = data;
  const newProducts = order.products.map((product, index) => ({ ...product, ...state.order.data.order.products[index] }));
  const newOrder = { ...order, products: newProducts };
  const newData = { ...data, order: newOrder };
  const buyerData =
    getIsOrderSplit(data) ?
      {
        // optional buyer should be removed after backend fix
        blockIfUnpaidInvoices: data.buyer?.blockIfUnpaidInvoices ?? false,
        hasUnpaidInvoices: data.buyer?.hasUnpaidInvoices ?? false,
      }
    : {};

  return update(state, {
    order: {
      data: newData,
      isFailed: false,
      isLoading: false,
    },
    ...buyerData,
  });
};

const handleAddAllProductsToOrderSuccess: ActionHandler<OrderReducer, typeof addAllProductsToOrderSuccess> = (state, action) =>
  update(state, {
    order: {
      data: action.payload,
      isFailed: false,
      isLoading: false,
    },
  });

/**
 * @deprecated Shouldn't we set `isFailed` and `isLoading`?
 */
const handleRemoveProductsFromOrderSuccess: ActionHandler<OrderReducer, typeof removeProductsFromOrderSuccess> = (state, action) =>
  update(state, {
    order: update(state.order, {
      data: action.payload!,
    }),
  });

const handleSetItemsRequest: ActionHandler<OrderReducer, typeof setItemsRequest> = (state, action) => {
  const { product, quantity, item, deliveryWindow } = action.payload!;

  return update(state, {
    optimisticUpdates: update(state.optimisticUpdates, {
      [product]: {
        ...state.optimisticUpdates[product],
        [`${deliveryWindow}-${item}`]: quantity,
      },
    }),
  });
};

const handleSetMultipleItemsRequest: ActionHandler<OrderReducer, typeof setMultipleItemsRequest> = (state, action) => {
  const { items, product } = action.payload;
  const [firstItem] = items;

  if (isEmpty(items) || !isDefined(firstItem)) {
    return state;
  }

  const { deliveryWindow } = firstItem;
  const quantities = items.reduce((acc, { item, quantity }) => {
    return { ...acc, [`${deliveryWindow}-${item}`]: quantity };
  }, {});

  return update(state, {
    optimisticUpdates: update(state.optimisticUpdates, {
      [product]: {
        ...state.optimisticUpdates[product],
        ...quantities,
      },
    }),
  });
};

const getOptimisticUpdateItemKey = (item: Omit<Unit.Stateful, 'variant' | 'product'>) => {
  return `${item.deliveryWindow}-${item.item}`;
};

const getOptimisticUpdatePrepackKey = (deliveryWindowId: DeliveryWindow.Id, variantId: Variants.Id, prepackId: Id) => {
  return `prepack-${deliveryWindowId}-${variantId}-${prepackId}`;
};

const getOptimisticUpdates = (items: Unit.ItemsDTO) =>
  items.reduce<{ [id: string]: number }>((acc, cur: Omit<Unit.Stateful, 'variant' | 'product'>) => {
    const key = getOptimisticUpdateItemKey(cur);

    return { ...acc, [key]: cur.quantity };
  }, {});

/**
 * @deprecated Shouldn't we set isFailed and isLoading explicitly?
 */
const handleSetItemsSuccess: ActionHandler<OrderReducer, typeof setItemsSuccess> = (state, action) => {
  return update(state, {
    optimisticUpdates: {},
    order: update(state.order, {
      data: update(state.order.data, {
        order: action.payload.order,
        products: action.payload.products,
        splitOrders: action.payload.splitOrders,
      }),
    }),
  });
};

const handleSetPrepacksRequest: ActionHandler<OrderReducer, typeof setPrepacksRequest> = (state, action) => {
  const { variant, prepacks, deliveryWindow } = action.payload.data;
  const { items, product } = action.payload!;
  const products = state.order.data.order.products.map(item =>
    item.product === product && item.variant === variant && deliveryWindow === item.deliveryWindow ? update(item, { prepacks }) : item,
  );
  const itemsQuantity = getOptimisticUpdates(items);
  const prepackItemsQuantity = prepacks.reduce<{ [id: string]: number }>((acc, cur) => {
    const key = getOptimisticUpdatePrepackKey(deliveryWindow, variant, cur.prepack);

    return { ...acc, [key]: cur.quantity };
  }, {});

  return update(state, {
    optimisticUpdates: update(state.optimisticUpdates, {
      [product]: {
        ...state.optimisticUpdates[product],
        ...itemsQuantity,
        ...prepackItemsQuantity,
      },
    }),
    order: update(state.order, {
      data: update(state.order.data, {
        order: update(state.order.data.order, {
          products,
        }),
      }),
    }),
  });
};

const handleRemovePrepacksRequest: ActionHandler<OrderReducer, typeof removePrepacksRequest> = (state, action) => {
  const { product } = action.payload;
  const { variant, deliveryWindow } = action.payload.data;
  const products = state.order.data.order.products.map(item =>
    item.product === product && item.variant === variant && deliveryWindow === item.deliveryWindow ? update(item, { prepacks: [] }) : item,
  );

  return update(state, {
    optimisticUpdates: update(state.optimisticUpdates, {
      [product]: {},
    }),
    order: update(state.order, {
      data: update(state.order.data, {
        order: update(state.order.data.order, {
          products,
        }),
      }),
    }),
  });
};

const handleSetItemsFailure = (state: OrderReducer): OrderReducer => {
  return update(state, {
    optimisticUpdates: {},
  });
};

const handleAddRemoveVoucherSuccess: ActionHandler<OrderReducer, typeof addVoucherSuccess | typeof removeVoucherSuccess> = (
  state,
  action,
) =>
  update(state, {
    order: update(state.order, {
      data: action.payload,
    }),
    voucher: {
      isSubmitting: false,
      isValid: true,
    },
  });

const handleAddVoucherFailure = (state: OrderReducer): OrderReducer =>
  update(state, {
    voucher: {
      isSubmitting: false,
      isValid: false,
    },
  });

const handleAddVoucherRequest = (state: OrderReducer) =>
  update(state, {
    voucher: {
      isSubmitting: true,
      isValid: true,
    },
  });

const handleLoadTermsSuccess: ActionHandler<OrderReducer, typeof loadTermsSuccess> = (state, action) =>
  update(state, {
    termsText: action.payload.termsAndConditions,
  });

const handleCheckoutOrderRequest = (state: OrderReducer): OrderReducer =>
  update(state, {
    isLoading: true,
    isSubmitting: true,
  });

/**
 * @deprecated
 * When an order has just been checked out, it contains an array of `orders`
 * instead of single `order` property. Use common shape for all orders.
 */
const handleCheckoutSingleOrderSuccess: ActionHandler<OrderReducer, typeof checkoutOrderSuccess> = (state, action) => {
  if ('order' in action.payload!) {
    report('API returned single order instead of list of orders. Decide on something.');
  }

  const order = 'order' in action.payload ? ((action.payload as any).order as Order.Closed) : action.payload.orders[0];

  return update(state, {
    isCheckoutSuccess: true,
    isLoading: false,
    isSubmitting: false,
    order: update(state.order, {
      data: update(state.order.data, {
        order,
      }),
    }),
  });
};

const handleCheckoutOrderFailure: ActionHandler<OrderReducer, typeof checkoutOrderFailure> = (state, action) =>
  update(state, {
    failedOrder: action.payload!,
    isLoading: false,
    isSubmitting: false,
    order: update(state.order, {
      data: update(state.order.data, {
        order: isDefined(action.payload.order) ? action.payload.order : state.order.data.order,
      }),
    }),
  });

const handleCheckoutOrderUnexpectedFailure = (state: OrderReducer): OrderReducer => {
  return update(state, {
    isLoading: false,
    isSubmitting: false,
  });
};

const handleCheckoutSplitOrderSuccess: ActionHandler<OrderReducer, typeof checkoutOrderSplitSuccess> = (state, action) => {
  const { currency, originalOrderId, orders } = action.payload!;

  return update(state, {
    checkoutSplit: {
      currency,
      orders,
      originalOrderId,
    },
    isLoading: false,
    isRequestingSplitOrder: true,
    isSubmitting: false,
    order: update(state.order, {
      data: {
        ...initialOrderData,
        currency: state.order.data.currency,
      },
    }),
  });
};

const handleResetCheckoutSuccess = (state: OrderReducer): OrderReducer =>
  update(state, {
    isCheckoutSuccess: false,
  });

const handleLoadInitialOrderDetails = (state: OrderReducer): OrderReducer =>
  update(state, {
    order: update(state.order, {
      data: initialOrderData,
    }),
  });

const handleUpdateOrderByShippingAddressSuccess: ActionHandler<OrderReducer, typeof updateOrderByShippingAddressSuccess> = (
  state,
  action,
) =>
  update(state, {
    order: update(state.order, {
      data: update(state.order.data, {
        order: update(state.order.data.order, action.payload.order),
        splitOrders: action.payload.splitOrders,
      }),
    }),
  });

const handleFetchInvoicesSuccess: ActionHandler<OrderReducer, typeof fetchInvoicesSuccess> = (state, action) => {
  const invoices = action.payload;

  return update(state, {
    invoices,
  });
};

const handleResetOrderData = (state: OrderReducer) => {
  return update(state, {
    checkoutSplit: initialState.checkoutSplit,
    initialSelectedShippingAddressId: null,
    order: update(state.order, {
      data: initialOrderData,
    }),
  });
};

const handleClearFailedProducts = (state: OrderReducer) => {
  return update(state, {
    failedProducts: null,
  });
};

const handleCopyToNewSelectionSuccess: ActionHandler<OrderReducer, typeof copyToNewSelectionSuccess> = (state, action) => {
  const { failedProducts } = action.payload!;

  return update(state, {
    failedProducts,
  });
};

const handleAddShippingAddress: ActionHandler<OrderReducer, typeof addShippingAddress> = (state, action) => {
  const orderData = update(state.order.data, {
    account: update(state.order.data.account, {
      shippingAddresses: [...(state.order.data.account.shippingAddresses ?? []), action.payload],
    }),
  });

  return update(state, {
    order: update(state.order, {
      data: update(state.order.data, orderData),
    }),
  });
};

const handleUpdateDefaultShippingAddress: ActionHandler<OrderReducer, typeof updateDefaultShippingAddress> = (state, action) => {
  const { account } = state.order.data;
  const orderData = update(state.order.data, {
    account: update(account, {
      shippingAddresses: [action.payload, ...(account.shippingAddresses ?? []).slice(1)],
    }),
  });

  return update(state, {
    order: update(state.order, {
      data: update(state.order.data, orderData),
    }),
  });
};

const handleSetActiveOrderPaymentMethod: ActionHandler<OrderReducer, typeof setActiveOrderPaymentMethod> = (state, action) => {
  return update(state, {
    paymentMethod: action.payload,
  });
};

const handleSelectionImportSuccess: ActionHandler<OrderReducer, typeof selectionImportSuccess> = (state, action) => {
  const orderData = omit(['completed', 'result'], action.payload);

  return update(state, {
    order: update(state.order, {
      data: update(state.order.data, orderData),
    }),
  });
};

const handleFetchUserSuccess: ActionHandler<OrderReducer, typeof fetchUserSuccess> = (state, action) => {
  const buyers = action.payload.account?.buyers;

  if (!isDefined(buyers)) {
    return state;
  }

  const [firstBuyer] = Object.values(buyers);

  return update(state, {
    // optional firstBuyer should be removed after backend fix
    blockIfUnpaidInvoices: firstBuyer?.blockIfUnpaidInvoices ?? false,
    hasUnpaidInvoices: firstBuyer?.hasUnpaidInvoices ?? false,
  });
};

const handleSetIsRequestingSplitOrder: ActionHandler<OrderReducer, typeof setIsRequestingSplitOrder> = (state, action) =>
  update(state, {
    isRequestingSplitOrder: action.payload,
  });

const handleUpdateCheckoutForm: ActionHandler<OrderReducer, typeof updateCheckoutForm> = (state, action) => {
  return update(state, {
    checkoutForm: action.payload,
  });
};

const handleResetCheckoutForm: ActionHandler<OrderReducer, typeof resetCheckoutForm> = state => {
  return update(state, {
    checkoutForm: undefined,
  });
};

const handleUpdateShippingRequest: ActionHandler<OrderReducer, typeof updateShippingRequest> = (state, action) => {
  return update(state, {
    pendingShipmentUpdates: uniq([...state.pendingShipmentUpdates, action.payload.orderId]),
  });
};

const handleUpdateShippingSuccess: ActionHandler<OrderReducer, typeof updateShippingSuccess> = (state, action) => {
  return update(state, {
    ...setOrderSuccess(state, action),
    pendingShipmentUpdates: without([action.payload.orderId], state.pendingShipmentUpdates),
  });
};

const handleUpdateShippingFailure: ActionHandler<OrderReducer, typeof updateShippingFailure> = (state, action) => {
  return update(state, {
    pendingShipmentUpdates: without([action.payload.orderId], state.pendingShipmentUpdates),
  });
};

export default createReducer<OrderReducer>(initialState)
  .handleAction(fetchOrderRequest, handleOrderRequest)
  .handleAction(fetchOrderFailure, handleOrderFailure)
  .handleAction(sharedSelectionFailure, handleOrderFailure)
  .handleAction(createSelectionSuccess, handleCreateSelectionSuccess)
  .handleAction(editSelectionSuccess, handleEditSelectionSuccess)
  .handleAction(addProductsToOrderSuccess, handleAddProductsToOrderSuccess)
  .handleAction(removeProductsFromOrderSuccess, handleRemoveProductsFromOrderSuccess)
  .handleAction(setItemsRequest, handleSetItemsRequest)
  .handleAction(setItemsSuccess, handleSetItemsSuccess)
  .handleAction(updateOrderByShippingAddressSuccess, handleUpdateOrderByShippingAddressSuccess)
  .handleAction(setItemsFailure, handleSetItemsFailure)
  .handleAction(setMultipleItemsRequest, handleSetMultipleItemsRequest)
  .handleAction(addVoucherRequest, handleAddVoucherRequest)
  .handleAction(addVoucherSuccess, handleAddRemoveVoucherSuccess)
  .handleAction(addVoucherFailure, handleAddVoucherFailure)
  .handleAction(removeVoucherSuccess, handleAddRemoveVoucherSuccess)
  .handleAction(loadTermsSuccess, handleLoadTermsSuccess)
  .handleAction(checkoutOrderRequest, handleCheckoutOrderRequest)
  .handleAction(checkoutOrderSuccess, handleCheckoutSingleOrderSuccess)
  .handleAction(checkoutOrderFailure, handleCheckoutOrderFailure)
  .handleAction(checkoutOrderUnexpectedFailure, handleCheckoutOrderUnexpectedFailure)
  .handleAction(checkoutOrderSplitSuccess, handleCheckoutSplitOrderSuccess)
  .handleAction(resetCheckoutSuccess, handleResetCheckoutSuccess)
  .handleAction(addAllProductsToOrderSuccess, handleAddAllProductsToOrderSuccess)
  .handleAction(loadInitialOrderDetails, handleLoadInitialOrderDetails)
  .handleAction(fetchInvoicesSuccess, handleFetchInvoicesSuccess)
  .handleAction(resetOrderData, handleResetOrderData)
  .handleAction(setPrepacksRequest, handleSetPrepacksRequest)
  .handleAction(removePrepacksRequest, handleRemovePrepacksRequest)
  .handleAction(copyToNewSelectionSuccess, handleCopyToNewSelectionSuccess)
  .handleAction(clearFailedProducts, handleClearFailedProducts)
  .handleAction(updateDefaultShippingAddress, handleUpdateDefaultShippingAddress)
  .handleAction(logoutRequest, handleLoadInitialOrderDetails)
  .handleAction(selectionImportSuccess, handleSelectionImportSuccess)
  .handleAction(fetchOrderSuccess, setOrderSuccess)
  .handleAction(updateShippingRequest, handleUpdateShippingRequest)
  .handleAction(updateShippingSuccess, handleUpdateShippingSuccess)
  .handleAction(updateShippingFailure, handleUpdateShippingFailure)
  .handleAction(fetchUserSuccess, handleFetchUserSuccess)
  .handleAction(addShippingAddress, handleAddShippingAddress)
  .handleAction(setIsRequestingSplitOrder, handleSetIsRequestingSplitOrder)
  .handleAction(updateCheckoutForm, handleUpdateCheckoutForm)
  .handleAction(resetCheckoutForm, handleResetCheckoutForm)
  .handleAction(setActiveOrderPaymentMethod, handleSetActiveOrderPaymentMethod);
