import { equals, omit, pipe, reject } from 'ramda';
import { matchPath } from 'react-router-dom';
import { replace } from 'redux-first-history';
import { combineEpics } from 'redux-observable';
import { filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { Filter } from '../../../typings';
import {
  fetchConfigurationSuccess,
  fetchDeliveryWindowsByBuyerIdSuccess,
  getBuyerIdFromCurrentOrder,
  getCurrentOrDefaultProductSortingValue,
  getDefaultStockType,
  getFilterFields,
  getIsStockTypeSeparationEnabled,
  getLocation,
  getOrderDeliveryWindows,
  getOrderNumber,
  getProductFilters,
  getProductSortingDefaultValue,
  getProductSortingOptions,
  getValidStockTypesByBuyerId,
  loadProductsRequest,
  loadProductsSuccess,
  locationChange,
  resetOrderData,
  selectWorkingOrder,
  setExpandedProductFilters,
  setInitialFilters,
  setProductSortingDefaultValue,
} from '../ducks';
import { parseObjectValuesToArray } from '../ducks/helpers';
import {
  decodeSearchString,
  encodeSearchString,
  getOnlyNeccessaryFilters,
  getValidatedFilters,
  getValidatedProductSorting,
} from '../logic/filters';
import { selectValidStockTypeForBuyer } from '../logic/stockType';
import { compiledPaths } from '../paths';
import { getQueryParams } from '../utils/getQueryParams';
import { isDefined } from '../utils/is';
import { isEmpty } from '../utils/isEmpty';

import { FILTERS_SYNC_ACTIONS_GROUP } from './actionGroups';

const getUrlFilters = pipe(decodeSearchString, parseObjectValuesToArray, getOnlyNeccessaryFilters);

const getIsProductsPage = () => isDefined(matchPath({ end: false, path: compiledPaths.PRODUCTS_ORDER({}) }, window.location.pathname));

const syncFiltersToUrlEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(FILTERS_SYNC_ACTIONS_GROUP)),
    filter(getIsProductsPage),
    map(() => {
      const filters = getProductFilters(state$.value);
      const location = getLocation(state$.value);
      const sorting = getCurrentOrDefaultProductSortingValue(state$.value);
      const defaultSorting = getProductSortingDefaultValue(state$.value);
      const filtersToEncode = getOnlyNeccessaryFilters(filters);

      const hasFiltersToApply = Object.values(filtersToEncode)
        .flat()
        .some(filterToEncode => !isEmpty(filterToEncode));

      const hasSortingToApply = defaultSorting !== sorting;
      const hasSharedToken = location?.search.includes('sharedToken');
      const isOpenPDM = location?.search.includes('showProduct');

      const hasAnyUrlParameter = hasFiltersToApply || hasSortingToApply || isOpenPDM || hasSharedToken;

      if (!hasAnyUrlParameter || !isDefined(location)) {
        return replace({ search: '' });
      }

      const currentQuery = getQueryParams(location.search, { comma: true, ignoreQueryPrefix: true });
      const { showProduct, showVariant, sharedToken, ...rest } = omit(['sort'], currentQuery);

      const query = {
        ...(hasSortingToApply && {
          sort: sorting,
        }),
        ...(hasFiltersToApply && rest),
        ...(showProduct && { showProduct }),
        ...(showVariant && { showVariant }),
        ...(sharedToken && { sharedToken }),
      };

      return replace({ search: encodeSearchString(query, filtersToEncode) });
    }),
  );

const syncFiltersFromUrlEpic: AppEpic = (action$, store$) =>
  action$.pipe(
    filter(action => {
      const isConfigurationLoadedAction = isActionOf(fetchConfigurationSuccess)(action);
      const isBrowserNavigationAction =
        isActionOf(locationChange)(action) && (action.payload.action === 'POP' || action.payload.action === 'PUSH');

      return isConfigurationLoadedAction || isBrowserNavigationAction;
    }),
    filter(getIsProductsPage),
    map(() => window.location.search),
    filter(searchQuery => !isEmpty(searchQuery)),
    mergeMap(searchQuery => {
      const urlFilters = getUrlFilters(searchQuery);

      const urlSorting = getQueryParams(searchQuery).sort;
      const currentFilters = getProductFilters(store$.value);
      const currentSorting = getCurrentOrDefaultProductSortingValue(store$.value);
      const isStockTypeSeparationEnabled = getIsStockTypeSeparationEnabled(store$.value);
      const defaultStockType = getDefaultStockType(store$.value);

      const nonEmptyFilters = reject(isEmpty, getOnlyNeccessaryFilters(currentFilters));
      const urlFiltersWithRequired = {
        ...urlFilters,
        ...(isStockTypeSeparationEnabled && !isDefined(urlFilters.stockType) ?
          { stockType: currentFilters.stockType ?? defaultStockType }
        : {}),
      };

      const newSorting = getValidatedProductSorting(urlSorting, getProductSortingOptions(store$.value));
      const newFilters = getValidatedFilters(urlFiltersWithRequired, getFilterFields(store$.value), isStockTypeSeparationEnabled);

      const hasLoadedOrder = isDefined(getOrderNumber(store$.value));
      const wasSortingUpdated = currentSorting !== newSorting;
      const wereFiltersUpdated = !equals(nonEmptyFilters, newFilters);

      const shouldReloadProducts = hasLoadedOrder && (wasSortingUpdated || wereFiltersUpdated);

      return [
        setProductSortingDefaultValue(newSorting),
        setInitialFilters(newFilters),
        ...(shouldReloadProducts ? [loadProductsRequest()] : []),
      ];
    }),
  );

const determineStockTypeFilter: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(fetchDeliveryWindowsByBuyerIdSuccess)),
    filter(() => getIsStockTypeSeparationEnabled(state$.value)),
    map(() => {
      const buyerId = getBuyerIdFromCurrentOrder(state$.value);
      const validStockTypes = getValidStockTypesByBuyerId(buyerId)(state$.value);
      const deliveryWindows = getOrderDeliveryWindows(state$.value);

      return { deliveryWindows, validStockTypes };
    }),
    filter(({ validStockTypes }) => {
      const urlFilters = getUrlFilters(window.location.search);

      const urlStockFilter = urlFilters.stockType;
      const storeStockFilter = getProductFilters(state$.value).stockType;

      const hasValidUrlStockFilter = isDefined(urlStockFilter) && validStockTypes.includes(urlStockFilter);
      const hasValidStoreStockFilter = isDefined(storeStockFilter) && validStockTypes.includes(storeStockFilter);

      return !hasValidUrlStockFilter && !hasValidStoreStockFilter;
    }),
    map(({ deliveryWindows, validStockTypes }) => {
      const hasStockDeliveryWindows = deliveryWindows.some(deliveryWindow => deliveryWindow.stockType === 'stock');
      const hasPreorderDeliveryWindows = deliveryWindows.some(deliveryWindow => deliveryWindow.stockType === 'preorder');

      if (hasStockDeliveryWindows && !hasPreorderDeliveryWindows) {
        return 'stock';
      }

      if (!hasStockDeliveryWindows && hasPreorderDeliveryWindows) {
        return 'preorder';
      }

      const defaultStockType = getDefaultStockType(state$.value);

      return selectValidStockTypeForBuyer(defaultStockType, validStockTypes);
    }),
    map(stockType => {
      const filters = getProductFilters(state$.value);

      return setInitialFilters({ ...filters, stockType });
    }),
  );

const validateArrivingSoonFilter: AppEpic = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(loadProductsSuccess)),
    filter(({ payload }) => {
      const availabilityFilter = payload.filter.find(({ field }) => field === 'onlyAvailable') as Filter.Field<string>;
      const filters = getProductFilters(store$.value);

      const isAvailableSoonEnabled = availabilityFilter?.values.find(value => value.filterValue === 'arrivingSoon');
      const hasAvailableSoonFilter = isDefined(filters.onlyAvailable) && filters.onlyAvailable === 'arrivingSoon';

      return !isAvailableSoonEnabled && hasAvailableSoonFilter;
    }),
    map(() => {
      const filters = getProductFilters(store$.value);

      return setInitialFilters({ ...omit(['etaFrom', 'etaTo'], filters), onlyAvailable: 'no' });
    }),
  );

const clearFiltersEpic: AppEpic = action$ =>
  action$.pipe(
    filter(isActionOf([selectWorkingOrder, resetOrderData])),
    mergeMap(() => [setInitialFilters({}), setExpandedProductFilters({})]),
  );

export const filtersEpic = combineEpics(
  syncFiltersFromUrlEpic,
  syncFiltersToUrlEpic,
  determineStockTypeFilter,
  clearFiltersEpic,
  validateArrivingSoonFilter,
);
