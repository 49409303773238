import { Checkout, DeliveryWindow, Order } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import universalStyles from '../../../../css/utilities/universal.module.scss';
import { getOrderDetails } from '../../../ducks';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { CheckoutShippingMethods } from '../../orders/partials/CheckoutShippingMethods';
import Heading from '../../various/Heading';
import { CheckoutSection } from '../CheckoutSection';
import { DelwinDates } from '../DelwinDates';

interface Props {
  deliveryWindows: DeliveryWindow.Mixed[];
  checkoutFields: Checkout.Requirements;
  splitOrders: Order.OpenSplit[];
  isLoading: boolean;
}

export const CheckoutMainOrderPreferences = ({ deliveryWindows, splitOrders, checkoutFields, isLoading }: Props) => {
  const { t } = useTranslation(['shipping']);
  const orderDetails = useSelector(getOrderDetails);

  const hasMultipleOrders = splitOrders.length > 1;
  const hasSplitByDeliveryWindow = React.useMemo(
    () => Object.values(splitOrders).every(order => order.splitBy === 'delwin'),
    [splitOrders],
  );

  const orderWithShippingMethods =
    isEmpty(orderDetails.order.shippingMethods ?? []) && !hasMultipleOrders ?
      orderDetails.splitOrders?.[0] ?? orderDetails.order
    : orderDetails.order;

  const hasAnyPreferredShippingDate =
    isDefined(checkoutFields.preferredShippingDate) || isDefined(checkoutFields.preferredShippingDateByDeliveryWindow);
  const hasAnyCancelShippingDate = isDefined(checkoutFields.cancelDate) || isDefined(checkoutFields.cancelDateByDeliveryWindow);

  const shouldShowShippingPreferences = hasAnyPreferredShippingDate || hasAnyCancelShippingDate;
  const shouldShowMainShippingPreferences = shouldShowShippingPreferences && (!hasMultipleOrders || !hasSplitByDeliveryWindow);
  const shouldShowMainShippingMethods = !isEmpty(orderWithShippingMethods.shippingMethods ?? []);

  if (!shouldShowMainShippingPreferences && !shouldShowMainShippingMethods) {
    return null;
  }

  return (
    <CheckoutSection>
      <div className={universalStyles.twoColumnsGrid}>
        {shouldShowMainShippingPreferences && (
          <DelwinDates isLoading={isLoading} deliveryWindows={deliveryWindows} checkoutFields={checkoutFields} />
        )}
        {shouldShowMainShippingMethods && (
          <div>
            <Heading title={t('shipping:shipping_method')} />
            <CheckoutShippingMethods
              orderId={orderDetails.order.order}
              splitId={orderWithShippingMethods.order}
              methods={orderWithShippingMethods.shippingMethods ?? []}
              selectedMethods={orderWithShippingMethods.selectedShippingMethods ?? []}
            />
          </div>
        )}
      </div>
    </CheckoutSection>
  );
};
